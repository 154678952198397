import { ImageGallery } from "components/core/Forms/Upload";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import { useConfigGetMany } from "hooks/useConfig";
import {
  getAbsoluteUrl,
  getMimeTypeFromUrl,
} from "helpers/mediaUtilities";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "components/Card";
import { Alert, Badge, Button } from "components/core";
import { convertHexToRGBA, isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { useMediaGetOneById } from "hooks/useMedia";

interface Props {
  setPageTitle: (title: string | React.ReactNode) => void;
}

export default function MediaPreview({ setPageTitle }: Props) {
  const { id } = useParams();
  const { data: config } = useConfigGetMany();
  const navigate = useNavigate();

  const { isLoading: isLoadingMedia, error: errorMedia, data: media } = useMediaGetOneById({
    enabled: !isNullEmptyOrWhitespace(id),
    id: id ?? "",
  });

  useEffect(() => {
    if (!!media) {
      setPageTitle(`Previewing - ${media?.filename}` ?? null);
    }
  }, [media, setPageTitle]);

  function handleClickBack(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();

    navigate(-1);
  }

  if (isLoadingMedia) {
    return <FieldsetSkeleton />;
  }

  if (errorMedia) {
    return <Alert theme="danger">{errorMedia}</Alert>;
  }

  if (!media) {
    return <Alert theme="warning">No media found.</Alert>;
  }

  return (
    <Card>
      <div className="grid grid-cols-1 gap-4">
        <ImageGallery
          className="col-span-full"
          images={[
            {
              url: getAbsoluteUrl(media.url, config?.mediaUrl),
              filetype: getMimeTypeFromUrl(getAbsoluteUrl(media.url, config?.mediaUrl)),
            },
          ]}
        />
        <Field label="Farm" value={media.farmname} />
        <Field label="Flock" value={media.flock} />
        <Field label="Name" value={media.filename} />
        <Field label="Description" value={media.description} />
        <Field label="Tag(s)" value={media?.tagsdata?.map((tag) => (
          <Badge
            icon={undefined}
            iconPosition={undefined}
            style={{
              backgroundColor:
                tag?.Color !== undefined
                  ? convertHexToRGBA(tag.Color, 0.05)
                  : null,
              color: tag?.Color !== undefined ? tag.Color : null,
              borderColor:
                tag?.Color !== undefined ? tag.Color : null,
            }}
          >
            {tag.Title}
          </Badge>
        ))} />
        <Field label="Created" value={media.createdat} />
        <Field label="Modified" value={media.modifiedat} />
        <div className="col-span-full">
          <Button
            className="justify-self-start"
            type="button"
            onClick={handleClickBack}
          >
            Back
          </Button>
        </div>
      </div>
    </Card>
  );
}

export function Field({
  label,
  value,
}: {
  label: string;
  value: string | React.ReactNode;
}) {
  if (isNullEmptyOrWhitespace(value)) {
    return null;
  }

  return (
    <div className="col-span-full text-sm">
      <div className="font-medium">{label}</div>
      <div>{value}</div>
    </div>
  );
}
