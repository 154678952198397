import { AppDataProvider } from "context/AppDataProvider";
import ConfigProvider from "context/ConfigProvider";
import FarmProvider from "context/FarmProvider";
import FormProvider from "context/FormProvider";
import MenuProvider from "context/MenuProvider";
import { ModalProvider } from "context/ModalProvider";
import { NavProvider } from "context/NavProvider";
import SidebarProvider from "context/SidebarProvider";
import { UserProvider } from "context/UserProvider";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  useLocation,
} from "react-router-dom";
import RootLayout from "./RootLayout";
import LoginPage from "pages/user/login";
import DebugPage from "pages/Debug";
import HomePage from "pages/index";
import { FormPage } from "./FormPage";
import { FormListPage } from "./FormListPage";
import SyncManagerPage from "pages/SyncManager";
import Error404Page from "pages/Error404";
import Error500 from "pages/Error500";
import { ActiveMenuProvider } from "hooks/useActiveMenu";
import NetworkStatusProvider from "context/NetworkStatusProvider";
import { useUser } from "hooks/useUser";
import OfflineNotification from "components/OfflineNotification";
import SyncDataProvider from "context/SyncDataProvider";
import SyncNotification from "components/SyncNotification";
import { Suspense, lazy } from "react";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";

const PlanningPlacementPage = lazy(() => import("pages/planning/placement"));

export const PrivateRoute: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { isSignedIn } = useUser();
  const location = useLocation();

  if (!isSignedIn) {
    return <Navigate to="/user/login" replace state={{ from: location }} />;
  }

  return <>{children}</>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/user/login" element={<LoginPage />} />

      <Route
        path="/"
        element={
          <NetworkStatusProvider>
            <UserProvider>
              <AppDataProvider>
                <ConfigProvider>
                  <FarmProvider>
                    <FormProvider>
                      <MenuProvider>
                        <SidebarProvider>
                          <NavProvider>
                            <ModalProvider>
                              <ActiveMenuProvider>
                                <PrivateRoute>
                                  <SyncDataProvider>
                                    <OfflineNotification />
                                    <SyncNotification />
                                    <Suspense fallback={<FieldsetSkeleton />}>
                                      <RootLayout />
                                    </Suspense>
                                  </SyncDataProvider>
                                </PrivateRoute>                                
                              </ActiveMenuProvider>
                            </ModalProvider>
                          </NavProvider>
                        </SidebarProvider>
                      </MenuProvider>
                    </FormProvider>
                  </FarmProvider>
                </ConfigProvider>
              </AppDataProvider>
            </UserProvider>
          </NetworkStatusProvider>
        }
        errorElement={
          <Route
            path="500"
            element={<Error500 />}
          />
        }
      >
        <Route path="/" element={<HomePage />} />
        <Route path="/planning/placement" element={<PlanningPlacementPage />} />
        <Route path="/:module" element={<FormListPage />} />
        <Route path="/:module/:moduleFeatureGroup" element={<FormListPage />} />
        <Route
          path="/:module/:moduleFeatureGroup/:formId"
          element={<FormPage />}
        />
        <Route
          path="/:module/:moduleFeatureGroup/:formId/:view"
          element={<FormPage />}
        />
        <Route
          path="/:module/:moduleFeatureGroup/:formId/:view/:id"
          element={<FormPage />}
        />
        <Route
          path="/:module/:moduleFeatureGroup/:formId/:view/:parentFormId/:parentId"
          element={<FormPage />}
        />
        <Route
          path="/:module/:moduleFeatureGroup/:formId/:view/:id/:parentFormId/:parentId"
          element={<FormPage />}
        />
        <Route path="/debug" element={<DebugPage />} />
        <Route path="/sync-manager" element={<SyncManagerPage />} />
        <Route path="500" element={<Error500 />} />
        <Route path="*" element={<Error404Page />} />
      </Route>
    </>
  )
);

const Pages = () => {
  return <RouterProvider router={router} />;
};

export default Pages;
