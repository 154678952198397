import { useCallback, useContext, useEffect, useState } from "react";
import {
  IForm,
} from "helpers/formUtilities";
import { FormContext } from "context/FormProvider";

const useForms = () => {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error("useForms must be used within a AppDataProvider");
  }

  return context;
};

type useFormGetManyProps = {
  enabled?: boolean;
};

export const useFormGetMany = ({
  enabled = true,
}: useFormGetManyProps = {}) => {
  const { data: forms } = useForms();

  const [isLoading, setIsLoading] = useState(enabled);
  const [data, setData] = useState<IForm[]>([]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    setData(forms);

    setIsLoading(false);

    return forms;
  }, [forms]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return {
    isLoading,
    data,
  };
};
