import { useCallback, useEffect, useState } from "react";
import useFetch from "./useFetch";
import { IMediaItem } from "helpers/mediaUtilities";
import { onErrorSchema, onSuccessSchema } from "./useFormData";

type useMediaCreateProps = {
  onSuccess?: onSuccessSchema;
  onError?: onErrorSchema;
};

export const useMediaCreate = ({
  onSuccess,
  onError,
}: useMediaCreateProps = {}) => {
  const { isLoading, error, execute } = useFetch(
    {
      onSuccess,
      onError,
    }
  );

  const mutate = useCallback(
    async (formData: FormData) => {
      const { data } = await execute("POST", "/api/media-post", formData);

      return data?.d ?? [];
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

type useMediaDeleteProps = {
  onSuccess?: onSuccessSchema;
  onError?: onErrorSchema;
};

export const useMediaDelete = ({
  onSuccess,
  onError,
}: useMediaDeleteProps = {}) => {
  const { isLoading, error, execute } = useFetch(
    {
      onSuccess,
      onError,
    }
  );

  const mutate = useCallback(
    async (filesToDelete: string[]) => {
      const { data } = await execute("DELETE", "/api/media-delete", {
        filesToDelete,
      });

      return data?.d ?? [];
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

type useMediaGetOneByIdProps = {
  enabled?: boolean;
  id: string;
};

export const useMediaGetOneById = ({
  enabled = true,
  id,
}: useMediaGetOneByIdProps) => {
  const { error, execute, isFetched } = useFetch();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IMediaItem | null>(null);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const { data } = await execute(
      "GET",
      `/api/media-get?id=${id}&limit=&offset=&order=&orderDirection=`
    );

    const newData = (data?.d?.[0] ?? null) as IMediaItem | null;

    setData(newData);

    setIsLoading(false);

    return newData;
  }, [execute, id]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data };
};

type useMediaGetManyProps = {
  enabled?: boolean;
  params?: Record<string, string>;
  limit?: number; // Optional limit parameter
  orderBy?: string; // Optional orderBy parameter
  orderByDirection?: "asc" | "desc"; // Optional orderByDirection parameter
};

export const useMediaGetMany = ({
  enabled = true,
  params,
  limit = 10, // Set a default limit value
  orderBy = "date", // Default orderBy parameter
  orderByDirection = "asc", // Default orderByDirection
}: useMediaGetManyProps) => {
  const { isLoading, error, execute } = useFetch();

  const [data, setData] = useState<IMediaItem[]>([]);
  const [offset, setOffset] = useState(0); // Offset for pagination
  const [hasMore, setHasMore] = useState(true); // State to keep track if there are more items to load

  const fetchData = useCallback(async () => {
    const paramKeys = Object.keys(params ?? {});

    const { data } = await execute(
      "GET",
      `/api/media-get?limit=${limit}&offset=${offset}&order=${orderBy}&orderDirection=${orderByDirection}${
        paramKeys.length && params
          ? "&" + paramKeys.map((key) => `${key}=${params[key]}`).join("&")
          : ""
      }`
    );

    const fetchedData = data?.d ?? [];

    setData((prevData) => [...prevData, ...fetchedData]);
    setHasMore(fetchedData.length === limit); // If fetched data is less than limit, there's no more data

    return fetchedData;
  }, [
    execute,
    params,
    limit,
    offset,
    orderBy,
    orderByDirection,
  ]);

  // Refetch function exposed to the parent component
  const refetch = useCallback(() => {
    setData([]); // Reset data
    setOffset(0); // Reset offset to the initial page
    setHasMore(true); // Assume there's more data to be fetched
    fetchData(); // Fetch data with the initial state
  }, [fetchData]);

  const loadMore = useCallback(() => {
    if (hasMore && !isLoading) {
      setOffset((prevOffset) => prevOffset + limit);
      fetchData();
    }
  }, [hasMore, isLoading, limit, fetchData]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, error, data, loadMore, hasMore, refetch };
};
