import React, { useContext, useEffect, useState } from "react";
import {
  useNavigate,
  useParams,
  Navigate,
} from "react-router-dom";
import {
  dateToString,
  startOfWeekFromDate,
  dateAdd,
  localDateFromUnix,
} from "helpers/dateUtilities";
import Fieldset from "components/forms/Fieldset";
import { FancyCounter } from "components/Animations";
import Form from "./Form";
import { isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { AppDataContext } from "context/AppDataProvider";
import { buildListPageUrl } from "helpers/redirectUtilities";
import { isNumeric } from "helpers/stringUtilities";
import { LOADED_STATUS } from "constants.js";
import useDeepCompareEffect from "use-deep-compare-effect";
import { getPenDataFromFormData } from "helpers/formUtilities";
import Card from "components/Card";
import { useActiveMenu } from "hooks/useActiveMenu";
import { useConfigGetMany } from "hooks/useConfig";
import { toast } from "react-toastify";
import { getFarmHousePen } from "helpers/farmUtilities";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";

export default function Production(props) {
  const [loaded, setLoaded] = useState(LOADED_STATUS.LOADING);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const { data: config } = useConfigGetMany();

  const navigate = useNavigate();
  const { activeMenu } = useActiveMenu(); 

  const { id: unixTimestamp } = useParams();
  const { pageSubtitle, setPageSubtitle } = useContext(AppDataContext);

  const {
    handleFormSubmit,
    dataStatus,
    farm,
    house,
    form,
    formValues,
    birdsAlive,
    birdAge = 0,
    formProps,
  } = props;

  //#region Callbacks

  //#endregion

  //#region Side-effects

  useEffect(() => {
    if (!isNull(farm)) {
      setLoaded(LOADED_STATUS.LOADED);
    }
  }, [farm]);

  // Set weekly schedule
  // TODO: might be better moving this to DynamicForm component
  useDeepCompareEffect(() => {
    if (
      form?.Schedule === undefined ||
      form?.FormName === undefined ||
      form?.FormName.toLowerCase() !== "weeklyproduction"
    ) {
      setStartDate(undefined);
      setEndDate(undefined);
      return;
    }

    const formDate = localDateFromUnix(unixTimestamp);

    const scheduledDayOfWeek = form.Schedule;

    // Start date
    let newFromDate = startOfWeekFromDate(formDate, {
      offset: scheduledDayOfWeek - 1,
    });
    if (newFromDate.getTime() > formDate.getTime()) {
      // From date exceeds current schedule item date,
      // go back a week
      newFromDate = dateAdd(newFromDate, -1, "weeks");
    }
    setStartDate(newFromDate);

    // End date
    const newToDate = dateAdd(newFromDate, 6, "days");
    setEndDate(newToDate);

    if (newToDate && newFromDate) {
      setPageSubtitle(
        `${dateToString(newFromDate, {
          includeWeekday: true,
        })} - ${dateToString(newToDate, { includeWeekday: true })}`
      );
    }
  }, [
    unixTimestamp,
    form?.FormName,
    form?.Schedule,
    setPageSubtitle,
    pageSubtitle,
  ]);

  //#endregion

  //#region Event handlers

  /**
   * Handle click cancel button
   */
  const handleClickCancel = () => {
    return navigate(buildListPageUrl(activeMenu));
  };

  //#endregion

  if (!form) return <FieldsetSkeleton />;

  // TODO performance improvement, avoid running on ever rerender
  const pen1 = getFarmHousePen(house, "1");
  const totalBirdsPlaced =
    house?.Pens?.reduce(
      (result, pen) =>
        (result += pen.Placement?.BirdsPlaced
          ? parseInt(pen.Placement?.BirdsPlaced)
          : 0),
      0
    ) ?? 0;

  if (
    loaded === LOADED_STATUS.LOADED &&
    !isNullEmptyOrWhitespace(house) &&
    isNull(pen1.Placement?.BirdsPlaced)
  ) {
    // Production requires that house has placement to continue
    toast.error(`No placements found for ${farm.FarmName} house #${house.HouseNumber}.`);

    return <Navigate to={buildListPageUrl(activeMenu)} />;
  }

  return (
    <Form
      {...formProps}
      onClickCancel={handleClickCancel}
      onFormSubmit={(ev) => {
        handleFormSubmit(ev);
      }}
      dataStatus={dataStatus}
      showDraft={[
        "production",
        "weeklyproduction",
        "monthlyproduction",
      ].includes(form?.FormName?.toLowerCase())}
      loaded={loaded}
      className="space-y-4"
    >
      <Card>
        <Fieldset
          {...props}
          key={`fieldset-house1`}
          title="House"
          text="This section contains general production info."
          content={
            form?.FormName.toLowerCase() === "production" && (
              <HouseStats
                className="mt-4 mb-4"
                stats={
                  !!pen1
                    ? [
                        {
                          name: "Bird Age",
                          stat:
                            farm?.FarmGroup?.toLowerCase() === "bro"
                              ? birdAge.days
                              : birdAge.weeks,
                        },
                        {
                          name: "Placed Date",
                          stat:
                            dateToString(
                              pen1.Placement?._DatePlaced?.normalised
                            ) ?? "N/A",
                        },
                        {
                          name: "Birds Placed",
                          stat: totalBirdsPlaced,
                        },
                        {
                          name: "Birds Alive",
                          stat: birdsAlive ?? "N/A",
                        },
                      ]
                    : []
                }
              />
            )
          }
          penNumber="1"
          fields={form?.FormFields.filter(
            (ff) => ff.Level.toLowerCase() === "h"
          )}
          formValues={getPenDataFromFormData("1", formValues)}
          startDate={startDate}
          endDate={endDate}
        />
      </Card>
      {form.FormName.toLowerCase() === "production" && Array.from({ length: house?.Pens?.length}).map((_, index) => {
        const offsetIndex = index + 1;
        const pen = getFarmHousePen(house, offsetIndex);
        if (!pen) return null;

        return (
          <Pen 
            key={`pen${offsetIndex}`} 
            index={offsetIndex} 
            pen={pen} 
            config={config} 
            formData={formValues}
            form={form}
            startDate={startDate}
            endDate={endDate}
            {...props} 
          />
        )
      })}
    </Form>
  );
}

function HouseStats({ stats, ...other }) {
  return (
    <div data-cy="house-stats" {...other}>
      <dl className="grid grid-cols-2 tablet:grid-cols-4 laptop:grid-cols-2 gap-2 tablet:divide-x laptop:divide-x-0">
        {stats.map((item) => (
          <div
            key={item.name}
            data-cy="stat"
            className="pl-2 py-2 tablet:pl-4 laptop:pl-2 overflow-hidden sm:p-6 flex flex-col-reverse"
            title={`${item.name} ${item.stat}`}
          >
            <dt className="text-xs tablet:text-sm text-gray-500 truncate">
              {item.name}
            </dt>
            <dd className="mt-1 mobile:text-xl tablet:text-base font-semibold text-gray-900">
              {isNumeric(item.stat) ? (
                <FancyCounter value={item.stat} duration={1000} />
              ) : (
                item.stat
              )}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}

function PenStats({ stats, ...other }) {
  return (
    <div data-cy="pen-stats" {...other}>
      <dl className="grid grid-cols-3 gap-4 divide-x">
        {stats.map((item) => (
          <div
            key={item.name}
            data-cy="stat"
            className="pl-2 py-2 tablet:pl-4 laptop:pl-2 overflow-hidden sm:p-6 flex flex-col-reverse"
            title={`${item.name} ${item.stat}`}
          >
            <dt className="text-xs tablet:text-sm text-gray-500 truncate">
              {item.name}
            </dt>
            <dd className="mt-1 mobile:text-xl tablet:text-base font-medium">
              {isNumeric(item.stat) ? (
                <FancyCounter value={item.stat} duration={1000} />
              ) : (
                item.stat
              )}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}

function Pen({ index, pen, config, formData, form, startDate, endDate, ...rest}) {
  if (!form) return null;

  const penLabel = pen?.Label;
  const penPrefix = config?.penLabelPrefix ?? "Pen";
  const penData = getPenDataFromFormData(index.toString(), formData);
  const excludedRefs = pen.ExcludedRefs?.toLowerCase()?.split(",") ?? [];
  const penFields = form?.FormFields.filter(
    (ff) =>
      ff.Level.toLowerCase() === "p" &&
      !excludedRefs.includes(ff.Ref.toLowerCase())
  );

  return (
    <Card>
      <Fieldset
        {...rest}
        key={`fieldset-pen${index}`}
        title={penLabel ?? `${penPrefix} ${index}`}
        text={config?.hideProdPenHeader !== "true" && form.FormName.toLowerCase() === "production" ? `This section contains information regarding Pen ${index}.` : ""}
        content={
          config?.hideProdPenHeader !== "true" && form.FormName.toLowerCase() === "production" && (
            <PenStats
              className="mt-4 mb-4"
              stats={
                [
                  {
                    name: "Birds Alive",
                    stat: penData?.BirdsAlive?.BirdsAlive ?? 0,
                  },
                  {
                    name: "Mortality",
                    stat: (
                      <FancyCounter
                        value={
                          penData?.Values?.find(
                            (fv) =>
                              fv.Ref.toLowerCase() === "totaldead"
                          )?.Value ?? 0
                        }
                        duration={1000}
                      />
                    ),
                  },
                ]
              }
            />
          )
        }
        penNumber={index}
        fields={penFields}
        formValues={penData}
        collapsible={config?.expandedProdPens === "true" ? false : true}
        startDate={startDate}
        endDate={endDate}
      />
    </Card>
  )
}