import { useContext, useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Historical from "components/Historical";
import { useNavigate } from "react-router-dom";
import { AppDataContext } from "context/AppDataProvider";
import useQuery from "hooks/useQuery";
import ButtonNew from "components/Historical/ButtonNew";
import useDeepCompareEffect from "use-deep-compare-effect";
import ButtonSkeleton from "components/core/Buttons/ButtonSkeleton";
import ListSkeleton from "components/core/Lists/ListSkeleton";
import { buildFormPageUrl } from "helpers/redirectUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { deepClone } from "helpers/dataUtilities";
import Card from "components/Card";
import { IFarm, calcGenericFormDataDates } from "helpers/farmUtilities";
import { IForm, IFormData } from "helpers/formUtilities";
import { IButtonNew } from "components/Historical/types";
import Dashboard from "components/Dashboard";
// import FarmSelector from "components/FarmSelector";
// import HouseSelector from "components/HouseSelector";
// import MenuSelector from "components/MenuSelector";
import Breadcrumb from "components/Breadcrumb";
import Tabs from "components/Tabs";
import { useActiveMenu } from "hooks/useActiveMenu";
import { useDashboardGetByMenuId } from "hooks/useDashboard";
import { useFormDataGetManyByFormType } from "hooks/useFormData";
import { localDateToSQLDate } from "helpers/dateUtilities";
import { useFarmGetMany } from "hooks/useFarm";
import { useFormGetMany } from "hooks/useForm";

export default function ListPage() {
  let location = useLocation();
  let navigate = useNavigate();

  const { activeMenu } = useActiveMenu();
  const moduleFeatureGroup = activeMenu?.ModuleFeatureGroup;
  const module = activeMenu?.Module;

  let query = useQuery();
  const farmId = query.get("farmId");
  const houseId = query.get("houseId");

  const { data: farms } = useFarmGetMany();
  const { data: forms } = useFormGetMany();

  const farm = farms.find((f: IFarm) =>
    !isNullEmptyOrWhitespace(farmId)
      ? f.FarmCode.toLowerCase() === farmId!.toLowerCase()
      : false
  );

  // const house = !isNullEmptyOrWhitespace(houseId) && !!farm ? getFarmHouse(farm!, parseInt(houseId!)) : undefined;

  // const placement = !!house ? getPlacementFromHouse(house, 1) : undefined;

  // const datePlaced = placement?._DatePlaced?.normalised;

  // const { fromDate, toDate, numDaysSincePlacement } = datePlaced
  //   ? calcProductionFormDataDates(datePlaced)
  //   : {
  //       fromDate: undefined,
  //       toDate: undefined,
  //       numDaysSincePlacement: undefined,
  //     };

  const { fromDate, toDate } = calcGenericFormDataDates();

  const { pageTitle, setPageTitle } = useContext(AppDataContext);

  const isFormDatasEnabled =
    !isNullEmptyOrWhitespace(farmId) &&
    !isNullEmptyOrWhitespace(houseId) &&
    !isNullEmptyOrWhitespace(moduleFeatureGroup) &&
    !isNullEmptyOrWhitespace(module) &&
    !isNullEmptyOrWhitespace(fromDate) &&
    !isNullEmptyOrWhitespace(toDate);
  const {
    data,
    isLoading: isFormDatasLoading,
  } = useFormDataGetManyByFormType({
    enabled: isFormDatasEnabled,
    farmId: farmId ?? "",
    houseId: parseInt(houseId ?? "0"),
    formType: moduleFeatureGroup?.toLowerCase() ?? "",
    moduleId: module ?? "",
    fromDate: localDateToSQLDate(fromDate) ?? "",
    toDate: localDateToSQLDate(toDate) ?? "",
  });

  const [sortedData, setSortedData] = useState<IFormData[]>(data);

  const [currentView, setCurrentView] = useState<string | undefined>(undefined);

  const [isLoading, setIsLoading] = useState(true);

  const userFarmGroups = farms?.reduce((acc, farm) => {
    const farmGroup = farm.FarmGroup.toLowerCase();

    if (!acc.includes(farmGroup)) {
      acc.push(farmGroup);
    }

    return acc;
  }, [] as string[]) ?? [];

  const { dashboards } = useDashboardGetByMenuId({
    enabled: !!activeMenu?.ID,
    id: activeMenu?.ID,
  });

  const dashboard = dashboards.find((d) => {
    const farmGroups = d?.farmGroups?.toLowerCase().split(",");

    return farmGroups?.some((farmGroup) => userFarmGroups.includes(farmGroup));
  });

  const filteredForms = useMemo(() => {
    let filteredForms = forms.filter(
      (f) =>
        f.FormType?.toLowerCase() === moduleFeatureGroup?.toLowerCase() &&
        f.ModuleName?.toLowerCase() === module?.toLowerCase()
    );

    const formsClone = deepClone(filteredForms) as IForm[];

    formsClone.forEach((f) => {
      // Filter form fields by FarmType
      if (farm?.FarmType) {
        f.FormFields = f.FormFields?.filter(
          (ff) =>
            isNullEmptyOrWhitespace(ff.FarmType) ||
            ff.FarmType?.split(",").some(
              (ft) => ft.toLowerCase() === farm.FarmType.toLowerCase()
            )
        );
      }

      // Filter form fields by FarmGroup
      if (farm?.FarmGroup) {
        f.FormFields = f.FormFields?.filter(
          (ff) =>
            isNullEmptyOrWhitespace(ff.FarmGroup) ||
            ff.FarmGroup?.split(",").some(
              (fg) => fg.toLowerCase() === farm.FarmGroup.toLowerCase()
            )
        );
      }
    });

    return formsClone;
  }, [forms, module, moduleFeatureGroup, farm?.FarmType, farm?.FarmGroup]);

  const [newButtonOptions, setNewButtonOptions] = useState<
    IButtonNew[] | undefined
  >(undefined);


  /**
   * Set page title
   */
  useEffect(() => {
    setPageTitle(activeMenu?.Title ?? "");
  }, [location.pathname, setPageTitle, activeMenu?.Title]);

  useEffect(() => {
    if (isFormDatasLoading || !isFormDatasEnabled) {
      setIsLoading(true);
      return;
    }

    const newSortedData = data?.sort((a, b) => {
      const aDate = a._DateApplies?.normalised?.getTime() ?? 0;
      const bDate = b._DateApplies?.normalised?.getTime() ?? 0;

      return bDate - aDate;
    });

    setSortedData(newSortedData ?? data);

    setIsLoading(false);
  }, [data, isFormDatasEnabled, isFormDatasLoading]);

  /**
   * Set new button options
   */
  useDeepCompareEffect(() => {
    const _forms = filteredForms.filter(
      (f) =>
        f.Permissions.includes("view") &&
        f.Permissions.includes("create") &&
        f.FormType?.toLowerCase() === moduleFeatureGroup?.toLowerCase()
    );

    setNewButtonOptions(
      _forms.map((f) => ({
        FormName: f.FormName,
        FormTitle: f.FormTitle,
        FormType: f.FormType,
      }))
    );
  }, [filteredForms, moduleFeatureGroup]);

  //#endregion

  //#region Listeners

  function handleButtonNewClick(formType: string, formName: string) {
    return navigate(buildFormPageUrl(activeMenu, formName));
  }

  //#endregion

  return (
    <main className="flex flex-col flex-grow overflow-x-hidden">
      <div className="relative z-20 bg-white border-b border-gray-100">
        <Breadcrumb showHome={false} farmRequired={true} houseRequired={true} />
      </div>
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <div className="flex flex-row items-center">
            <div className="text-lg text-gray-600 uppercase flex-grow font-medium">
              {pageTitle}
            </div>
            {newButtonOptions !== undefined && filteredForms.length > 0 ? (
              // <div className="absolute bottom-4 right-4">
              <ButtonNew
                className="justify-end"
                onClick={handleButtonNewClick}
                options={newButtonOptions}
              />
            ) : (
              // </div>
              <ButtonSkeleton />
            )}
          </div>
        </div>

        {!!dashboard && farmId !== null && houseId !== null && (
          <div className="col-span-full">
            <Tabs
              tabs={[
                {
                  name: "List",
                  id: "list",
                  current: currentView === undefined || currentView === "list",
                },
                {
                  name: "Dashboard",
                  id: "dashboard",
                  current: currentView === "dashboard",
                },
              ]}
              onChange={(id: string) => setCurrentView(id)}
            ></Tabs>
          </div>
        )}

        {(currentView === "list" || currentView === undefined) && (
          <div className="col-span-full">
            <Card>
              {(isLoading) ? (
                <ListSkeleton />
              ) : (
                <div className="flex flex-col flex-grow space-y-4">
                  <Historical
                    className="-mx-4"
                    farmId={farmId?.toLowerCase() ?? ""}
                    houseId={houseId?.toString() ?? ""}
                    forms={filteredForms.filter((f) =>
                      f.Permissions.includes("view")
                    )}
                    items={sortedData}
                  />
                </div>
              )}
            </Card>
          </div>
        )}

        {currentView === "dashboard" && !!dashboard && (
          <div className="col-span-full">
            <Dashboard dashboard={dashboard} />
          </div>
        )}
      </div>
    </main>
  );
}
