import { DBFormDataQueue, FORM_DATA_QUEUE_STATUS } from "db/FormDataQueue";
import ListItem from "./ListItem";
import { db } from "db";

const SyncList: React.FC<{ items: DBFormDataQueue[] }> = ({ items }) => {
  const handleResetSendAttempts = async (item: DBFormDataQueue) => {
    console.log("handleRetrySync", item);

    if (item.sendStatus !== FORM_DATA_QUEUE_STATUS.FAILED) {
      return;
    }

    await db.formdataqueue.update(item.id, {
      sendAttempts: 0,
      sendStatus: FORM_DATA_QUEUE_STATUS.PENDING,
    });
  }

  return (
    <ul className="divide-y divide-gray-100">
      {items.map((item) => (
        <ListItem key={item.id} item={item} handleResetSendAttempts={handleResetSendAttempts} />
      ))}
    </ul>
  );
};

export default SyncList;
