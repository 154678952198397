import BackButton from "components/BackButton";
import PageHeader from "components/PageHeader";
import SyncList from "components/sync-manager/List";
import SyncListEmpty from "components/sync-manager/ListEmpty";
import { FORM_DATA_QUEUE_STATUS } from "db/FormDataQueue";
import { useSyncFormDataGetMany } from "hooks/useFormData";

const excludedStatus = [FORM_DATA_QUEUE_STATUS.SENT]

const SyncManagerPage: React.FC = () => {
  const { data } = useSyncFormDataGetMany({
    excludedStatus
  });

  return (
    <div className="flex-grow overflow-x-hidden">
      <div className="relative z-20">
        <PageHeader className="py-6 px-4 sm:px-6 lg:px-8">
          <div className="text-xl">Sync manager</div>
          <p className="italic mt-4 text-sm">
            Important: To avoid data loss, please don't clear your browser cache
            for this app.
          </p>
        </PageHeader>
      </div>
      <main className="flex flex-col flex-grow">
        <div className="grid grid-cols-2 gap-4 p-4">
          <div className="col-span-full">
            {!!data?.length && <SyncList items={data} />}
            {!data?.length && <SyncListEmpty className="py-6" />}
          </div>
          <div className="col-span-full">
            <BackButton />
          </div>
        </div>
      </main>
    </div>
  );
};

export default SyncManagerPage;
