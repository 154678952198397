import useNetworkStatus from "hooks/useNetworkStatus";
import { Alert } from "./core";
import useSyncData from "hooks/useSyncData";

export default function SyncPendingNotification({ count }: { count: number }) {
  const { isSyncing, sync } = useSyncData();
  const { isOnline } = useNetworkStatus();

  if (count <= 0 || !isOnline) {
    return null;
  }

  if (isSyncing) {
    return (
      <div>
        <Alert theme="success" size="sm" className="cursor-pointer">
          <p className="text-sm font-medium">Syncing data...</p>
          <p>
            Please wait while your submission(s) are being synced. Do not refresh
            or close the app until complete.
          </p>
        </Alert>
      </div>
    );
  }

  const handleSync = () => {
    sync();
    return;
  };

  return (
    <div>
      <Alert
        theme="warning"
        size="sm"
        className="cursor-pointer"
        onClick={handleSync}
      >
        <p className="text-sm font-medium">Sync pending data</p>
        <p>
          You have {count} "pending" submission(s). Click here to manually sync them now.
        </p>
        <p className="italic">Important: please ensure you have a reliable internet connection before clicking to sync.</p>
      </Alert>
    </div>
  );
}
