import { sqlDateObjectFromServerTZ } from "helpers/dateUtilities";
import { IFarm } from "helpers/farmUtilities";
import { parseJSON } from "helpers/stringUtilities";
import useFetch from "hooks/useFetch";
import { useUser } from "hooks/useUser";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";

export interface IFarmsContext {
  data: IFarm[];
}

const FarmsContext = createContext({} as IFarmsContext);

const defaultData: IFarm[] = [];

const FarmProvider = ({ children }: { children: React.ReactNode }) => {
  const { execute } = useFetch();

  const { isSignedIn } = useUser();

  const [data, setData] = useState<IFarm[]>(defaultData);

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/farms-get");

    const farms: IFarm[] = data?.d ?
      data.d instanceof Array ? data.d : parseJSON(data.d) :
      defaultData;

    // Change all server datetimes to local date object, making comparison easier going forward
    const newData = farms.map((record) => {
      return {
        ...record,
        Houses: record?.Houses.map((house) => {
          return {
            ...house,
            Pens: house?.Pens.map((pen) => {
              return {
                ...pen,
                Placement: {
                  ...pen?.Placement,
                  _HatchDate: sqlDateObjectFromServerTZ(
                    pen?.Placement?.HatchDate
                  ),
                  _CropDate: sqlDateObjectFromServerTZ(
                    pen?.Placement?.CropDate
                  ),
                  _DatePlaced: sqlDateObjectFromServerTZ(
                    pen?.Placement?.DatePlaced
                  ),
                  _DepopDate: sqlDateObjectFromServerTZ(
                    pen?.Placement?.DepopDate
                  ),
                },
              };
            }),
          };
        }),
      };
    });

    newData.sort((a, b) => a.FarmCode.localeCompare(b.FarmCode));

    setData(newData);

    return newData;
  }, [execute]);

  useEffect(() => {
    if (isSignedIn) {
      fetchData();
    }
  }, [isSignedIn, fetchData]);

  const contextValue = useMemo(() => ({ data }), [data]);

  return (
    <FarmsContext.Provider
      value={contextValue}
    >
      {children}
    </FarmsContext.Provider>
  );
};

export { FarmProvider as default, FarmsContext };
