import React, { useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import { DocumentTextIcon, DownloadIcon, PhotographIcon, XCircleIcon } from "assets/icons";
import { resizeImage } from "helpers/imageUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { getAbsoluteUrl, getMimeTypeFromUrl } from "helpers/mediaUtilities";
import { useConfigGetMany } from "hooks/useConfig";
import FieldSkeleton from "../FieldSkeleton";
import { Alert } from "components/core/Notifications";
import { toast } from "react-toastify";

export function Upload({
  id,
  validate,
  value,
  setValue,
  setValid,
  required = true,
  accept = "image/*",
  multiple = false,
  defaultLabel = "Upload new file",
  render = true,
  uploadLimit = 3,
}) {
  const { isLoading: isLoadingConfig, data: config } = useConfigGetMany({ keys: "mediaUrl" });

  const [label, setLabel] = useState(defaultLabel);
  const [error, setError] = useState(false);

  const inputFileField = useRef(undefined);

  useEffect(() => {
    if (typeof value == "string") {
      // Handle initial CSV string from DB
      // No pending files have been selected yet
      const _parsedSaved =
        value.split(",").filter((v) => !isNullEmptyOrWhitespace(v)) ?? [];

      setValue({
        saved: _parsedSaved,
        pending: [],
        deleting: [],
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  /**
   * Trigger validation when value or dependencies change.
   * Useful when you wish to revalidate when related input changes.
   */
  useEffect(() => {
    let validationResult = validation();

    if (value?.pending?.length) {
      for (const file of value.pending) {
        if (!isValidFileType(file)) {
          validationResult = `File ${file.name} file type is invalid.`;
        }
      }
    }

    if (validationResult !== error) setError(validationResult);

    const numOfImages = (value?.saved?.length ?? 0) + (value?.pending?.length ?? 0);
    const complete = required && numOfImages < 1 ? false : true;
    if (setValid) setValid(!validationResult, value, required, complete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, required]);

  /**
   * Validate the input value.
   * @returns {string|null} The error message or null if valid.
   */
  const validation = () => {
    if (validate) return validate(value);
  };

  const handleDrop = async (ev) => {
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === "file") {
          const file = ev.dataTransfer.items[i].getAsFile();

          // Validate acceptable filetype
          if (!isValidFileType(file)) {
            toast.error(`File ${file.name} file type is invalid.`);
          }

          // Compress file
          let newFile = file;
          if (file.type.startsWith("image/")) {
            newFile = await resizeImage(file, {
              maxWidth: 1920,
              maxHeight: 1920,
              quality: 70,
            });
          }

          // Save new form values
          const pending = value?.pending.map((v) => v) ?? [];
          pending.push(newFile);
          const newValue = {
            ...value,
            pending,
          };
          setValue(newValue);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        const file = ev.dataTransfer.files[i];

        // Validate acceptable filetype
        if (!isValidFileType(file)) {
          toast.error(`File ${file.name} file type is invalid.`);
        }

        // Compress file
        let newFile = file;
        if (file.type.startsWith("image/")) {
          newFile = await resizeImage(file, {
            maxWidth: 1920,
            maxHeight: 1920,
            quality: 70,
          });
        }

        // Save new form values
        const pending = value?.pending.map((v) => v) ?? [];
        pending.push(newFile);
        const newValue = {
          ...value,
          pending,
        };
        setValue(newValue);
      }
    }

    // clear file input value
    inputFileField.current.value = null;
  };

  const handleSelect = async (ev) => {
    ev.preventDefault();

    // Use DataTransfer interface to access the file(s)

    for (const [, file] of [...ev.target.files].entries()) {
      // Validate acceptable filetype
      if (!isValidFileType(file)) {
        toast.error(`File ${file.name} file type is invalid.`);
      }

      // Compress file
      let newFile = file;
      if (file.type.startsWith("image/")) {
        newFile = await resizeImage(file, {
          maxWidth: 1920,
          maxHeight: 1920,
          quality: 70,
        });
      }

      // Save new form values
      const pending = value?.pending.map((v) => v) ?? [];
      pending.push(newFile);
      const newValue = {
        ...value,
        pending,
      };
      setValue(newValue);
    }

    // clear file input value
    inputFileField.current.value = null;
  };

  const handleDragOver = (ev) => {
    ev.preventDefault();

    setLabel("Drop file to upload");
  };

  const handleDragLeave = (ev) => {
    ev.preventDefault();

    setLabel(defaultLabel);
  };

  const handleClick = (ev) => {
    ev.preventDefault();

    // Open file selection window
    inputFileField.current.click();
  };

  const handleDeletePending = (index) => {
    // Avoid mutation
    const pending = value?.pending.map((v) => v) ?? [];
    // Remove from `pending`
    pending.splice(index, 1);
    // Store updated value
    const newValue = {
      ...value,
      pending,
    };
    setValue(newValue);
  };

  const handleDeleteSaved = (index) => {
    // Avoid mutation
    const saved = value?.saved.map((v) => v) ?? [];
    const deleting = value?.deleting.map((v) => v) ?? [];
    // Remove from `saved` and push to `deleting`
    const toDelete = saved.splice(index, 1);
    deleting.push(toDelete[0]);
    // Store updated value
    const newValue = {
      ...value,
      saved,
      deleting,
    };
    setValue(newValue);
  };

  const isValidFileType = (file) => {
    const acceptedFileTypes = accept.split(",").map((v) => v.trim());

    if (acceptedFileTypes.includes("*/*")) return true;
    if (acceptedFileTypes.includes(file.type)) return true;
    if (acceptedFileTypes.includes(file.type.split("/")[0] + "/*")) return true;
    return false;
  };

  // Prevent dom element rendering
  if (render === false) {
    return null;
  }

  const savedLength = value?.saved?.length ?? 0;
  const pendingLength = value?.pending?.length ?? 0;

  if (isLoadingConfig) {
    return <FieldSkeleton />;
  }
  
  return (
    <>
      {config.mediaUrl ? (
        value?.saved instanceof Array && savedLength > 0 && (
          <ImageGallery
            className="mb-2"
            images={value.saved.map((url) => ({
                url: getAbsoluteUrl(url, config.mediaUrl),
                filetype: getMimeTypeFromUrl(getAbsoluteUrl(url, config.mediaUrl)),
              })
            )}
            onDelete={handleDeleteSaved}
          >
            <div className="font-medium">Saved upload(s)</div>
          </ImageGallery>
        )
      ) : isLoadingConfig ? (
        <FieldSkeleton />
      ) : (
        <Alert theme="danger">
          Media URL is not set in the config.
        </Alert>
      )}
      {pendingLength > 0 && (
        <>
          <div className="font-medium">Pending upload(s)</div>
          <ul className="grid grid-cols-3 gap-4">
            {value.pending.map((file, index) => {
              return (
                <li key={`${file.name}_${index}`} className="relative">
                  <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 border border-gray-300 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden relative">
                  {file.type.startsWith("image/") ? (
                    <img
                      src={URL.createObjectURL(file)}
                      alt=""
                      className="object-cover pointer-events-none group-hover:opacity-75 min-w-full"
                    />
                  ) : file.type.startsWith("application/pdf") ? (
                    <svg
                      className="object-cover pointer-events-none group-hover:opacity-75 min-w-full text-gray-300"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      // stroke="currentColor"
                    >
                      <path d="M20,6.52897986 L20,19.5010024 C20,20.8817143 18.8807119,22.0010024 17.5,22.0010024 L6.5,22.0010024 C5.11928813,22.0010024 4,20.8817143 4,19.5010024 L4,4.50100238 C4,3.1202905 5.11928813,2.00100238 6.5,2.00100238 L15.4720225,2.00100238 C15.6047688,1.99258291 15.7429463,2.03684187 15.8535534,2.14744899 L19.8535534,6.14744899 C19.9641605,6.25805611 20.0084195,6.39623363 20,6.52897986 Z M15,3.00100238 L6.5,3.00100238 C5.67157288,3.00100238 5,3.67257525 5,4.50100238 L5,19.5010024 C5,20.3294295 5.67157288,21.0010024 6.5,21.0010024 L17.5,21.0010024 C18.3284271,21.0010024 19,20.3294295 19,19.5010024 L19,7.00100238 L15.5,7.00100238 C15.2238576,7.00100238 15,6.77714475 15,6.50100238 L15,3.00100238 Z M16,3.70810916 L16,6.00100238 L18.2928932,6.00100238 L16,3.70810916 Z M12.2879737,11.9579134 L13.817339,15.0166439 C13.8765619,15.0057128 13.9376138,15 14,15 L15,15 C15.5522847,15 16,15.4477153 16,16 L16,17 C16,17.5522847 15.5522847,18 15,18 L14,18 C13.4477153,18 13,17.5522847 13,17 L13,16 L10,16 L10,17 C10,17.5522847 9.55228475,18 9,18 L8,18 C7.44771525,18 7,17.5522847 7,17 L7,16 C7,15.4477153 7.44771525,15 8,15 L9,15 C9.06238619,15 9.12343806,15.0057128 9.18266103,15.0166439 L10.7120263,11.9579134 C10.3001179,11.8342569 10,11.4521766 10,11 L10,10 C10,9.44771525 10.4477153,9 11,9 L12,9 C12.5522847,9 13,9.44771525 13,10 L13,11 C13,11.4521766 12.6998821,11.8342569 12.2879737,11.9579134 L12.2879737,11.9579134 Z M11,10 L11,11 L12,11 L12,10 L11,10 Z M8,16 L8,17 L9,17 L9,16 L8,16 Z M14,16 L14,17 L15,17 L15,16 L14,16 Z M11.5,12.618034 L10.309017,15 L12.690983,15 L11.5,12.618034 Z" />
                    </svg>
                  ) : (
                    <DocumentTextIcon className="object-cover pointer-events-none group-hover:opacity-75 min-w-full text-gray-300" />
                  )}

                    <button
                      type="button"
                      className="absolute top-2 right-2 focus:outline-none group "
                      onClick={() => handleDeletePending(index)}
                    >
                      <XCircleIcon className="mx-auto h-6 w-6 text-white group-hover:text-primary pointer-events-none" />
                      <span className="sr-only">Delete {file.name}</span>
                    </button>
                  </div>
                  <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
                    {file.name}
                  </p>
                  <p className="block text-xs font-medium text-gray-500 pointer-events-none mb-2">
                    Size: {(file.size / (1024*1024)).toFixed(2)} kb
                  </p>
                </li>
              );
            })}
          </ul>
        </>
      )}
      <input
        ref={inputFileField}
        id={id}
        type="file"
        name="file"
        onChange={handleSelect}
        accept={accept}
        multiple={multiple}
        hidden
      />
      {savedLength + pendingLength < uploadLimit ? (
        <button
          type="button"
          className="relative flex items-center desktop:block w-full border desktop:border-2 border-gray-300 desktop:border-dashed rounded-lg p-2 desktop:p-8 text-center hover:border-gray-400 focus:outline-none focus:ring-0"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onClick={handleClick}
        >
          <PhotographIcon className="mr-2 desktop:mx-auto h-6 w-6 desktop:h-12 desktop:w-12 text-gray-400 pointer-events-none" />
          <span className="desktop:mt-2 block text-sm font-medium text-gray-500 pointer-events-none">
            {label}
          </span>
        </button>
      ) : null}
      <div className="text-xs text-gray-500" id="email-error">
        {savedLength + pendingLength} of {uploadLimit}{" "}
        files
      </div>
      {error ? (
        <p className="mt-2 text-xs text-danger-600">
          {error}
        </p>
      ) : null}
    </>
  );
}

Upload.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  validate: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setValue: PropTypes.func,
  setValid: PropTypes.func,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  defaultLabel: PropTypes.string,
  render: PropTypes.bool,
};

export function ImageGallery({ images, children = undefined, onDelete = undefined, ...other }) {
  return (
    <div {...other}>
      {children}
      <ul className="grid grid-cols-3 gap-4">
        {images.map((image, index) => {
          return (
            <li key={`${image.url}`} className="relative">
              <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 border border-gray-300 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden relative">
                {image.filetype.startsWith("image/") ? (
                  <img
                    src={image.url}
                    alt=""
                    className="object-cover pointer-events-none group-hover:opacity-75 min-w-full"
                  />
                ) : image.filetype.startsWith("application/pdf") ? (
                  <svg
                    className="object-cover pointer-events-none group-hover:opacity-75 min-w-full text-gray-300"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    // stroke="currentColor"
                  >
                    <path d="M20,6.52897986 L20,19.5010024 C20,20.8817143 18.8807119,22.0010024 17.5,22.0010024 L6.5,22.0010024 C5.11928813,22.0010024 4,20.8817143 4,19.5010024 L4,4.50100238 C4,3.1202905 5.11928813,2.00100238 6.5,2.00100238 L15.4720225,2.00100238 C15.6047688,1.99258291 15.7429463,2.03684187 15.8535534,2.14744899 L19.8535534,6.14744899 C19.9641605,6.25805611 20.0084195,6.39623363 20,6.52897986 Z M15,3.00100238 L6.5,3.00100238 C5.67157288,3.00100238 5,3.67257525 5,4.50100238 L5,19.5010024 C5,20.3294295 5.67157288,21.0010024 6.5,21.0010024 L17.5,21.0010024 C18.3284271,21.0010024 19,20.3294295 19,19.5010024 L19,7.00100238 L15.5,7.00100238 C15.2238576,7.00100238 15,6.77714475 15,6.50100238 L15,3.00100238 Z M16,3.70810916 L16,6.00100238 L18.2928932,6.00100238 L16,3.70810916 Z M12.2879737,11.9579134 L13.817339,15.0166439 C13.8765619,15.0057128 13.9376138,15 14,15 L15,15 C15.5522847,15 16,15.4477153 16,16 L16,17 C16,17.5522847 15.5522847,18 15,18 L14,18 C13.4477153,18 13,17.5522847 13,17 L13,16 L10,16 L10,17 C10,17.5522847 9.55228475,18 9,18 L8,18 C7.44771525,18 7,17.5522847 7,17 L7,16 C7,15.4477153 7.44771525,15 8,15 L9,15 C9.06238619,15 9.12343806,15.0057128 9.18266103,15.0166439 L10.7120263,11.9579134 C10.3001179,11.8342569 10,11.4521766 10,11 L10,10 C10,9.44771525 10.4477153,9 11,9 L12,9 C12.5522847,9 13,9.44771525 13,10 L13,11 C13,11.4521766 12.6998821,11.8342569 12.2879737,11.9579134 L12.2879737,11.9579134 Z M11,10 L11,11 L12,11 L12,10 L11,10 Z M8,16 L8,17 L9,17 L9,16 L8,16 Z M14,16 L14,17 L15,17 L15,16 L14,16 Z M11.5,12.618034 L10.309017,15 L12.690983,15 L11.5,12.618034 Z" />
                  </svg>
                ) : (
                  <DocumentTextIcon className="object-cover pointer-events-none group-hover:opacity-75 min-w-full text-gray-300" />
                )}
                
                {onDelete ? <button
                  type="button"
                  className="absolute top-2 right-2 focus:outline-none group "
                  onClick={() => onDelete(index)}
                >
                  <XCircleIcon className="mx-auto h-6 w-6 text-white group-hover:text-primary pointer-events-none" />
                  <span className="sr-only">Delete {image.url}</span>
                </button>
                : null}
              </div>
              <a href={image.url} className="mt-2 text-sm text-primary hover:text-primary-dark" target="_blank" rel="noreferrer">
                <DownloadIcon className="h-4 w-4 inline-block mr-2" />
                Download
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
