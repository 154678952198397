import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  dateFromDayOfYear,
  dateToString,
  startOfFromDate,
} from "helpers/dateUtilities";
import { ListItem } from "components/core";
import ListItemIcon from "components/Schedule/ListItemIcon";
import ListItemStatus from "components/Schedule/ListItemStatus";
import { isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { buildFormPageUrl } from "helpers/redirectUtilities";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useActiveMenu } from "hooks/useActiveMenu";

export default function ListItemMonthly({
  todayEntries,
  event,
  date,
  dateToday,
  dateHatched,
  formId,
  className,
  ...other
}) {
  let navigate = useNavigate();
  const { activeMenu } = useActiveMenu();

  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [entry, setEntry] = useState(undefined);

  //#region Side-effects

  /**
   * Set entry
   */
  useDeepCompareEffect(() => {
    if (isNull(todayEntries) || isNullEmptyOrWhitespace(formId)) {
      setEntry(undefined);

      return;
    }

    const _newEntry = todayEntries.find(
      (e) => e.FormName.toLowerCase() === formId
    );
    setEntry(_newEntry);
  }, [todayEntries, formId]);

  /**
   * Set To & From dates
   */
  useEffect(() => {
    if (isNullEmptyOrWhitespace(event) || isNull(date)) return;

    const { StartDays, EndDays } = event;

    // By calendar days of year
    // Start date
    const newFromDate = dateFromDayOfYear(StartDays, {
      year: date.getFullYear(),
    });
    setFromDate(newFromDate);
    // End date
    const newToDate = dateFromDayOfYear(EndDays, { year: date.getFullYear() });
    setToDate(newToDate);
  }, [event, date]);

  //#endregion

  //#region Event handlers

  /**
   * Handle list item click
   */
  const handleClick = () => {
    const recordId = event.FarmScheduleID || date.getTime();

    return navigate(
      buildFormPageUrl(
        activeMenu,
        formId,
        null,
        null,
        recordId
      )
    );
  };

  //#endregion

  //#region Helpers

  /**
   * Should the list item be shown
   * @returns {boolean} True/false the list item should show.
   */
  const shouldShow = () => {
    const completedDate = event?._CompletedDate;
    // Completed event
    if (completedDate && completedDate.normalised.getTime() === date?.getTime())
      return true;

    // Event list date is the past && toDate is valid in future, don't render
    if (
      !completedDate &&
      date?.getTime() < startOfFromDate(dateToday, "day").getTime() &&
      toDate?.getTime() > date?.getTime()
    )
      return false;

    // Event list date is between from & to date, render
    if (
      !completedDate &&
      (date?.getTime() >= fromDate?.getTime() ||
        date?.getTime() <= toDate?.getTime())
    )
      return true;

    return false;
  };

  //#endregion

  return shouldShow() ? (
    <ListItem aria-label="monthly" onClick={() => handleClick()} {...other}>
      <ListItemIcon
        dataStatus={entry?.Status}
        networkStatus={entry?._SendStatus}
        date={date}
        dateToday={dateToday}
      />
      <div className="flex-grow">
        <div className="font-medium">Monthly</div>
        <div className="text-sm text-gray-900" data-cy="date-range">
          {fromDate && `${dateToString(fromDate, { includeWeekday: true })} -`}{" "}
          {toDate && `${dateToString(toDate, { includeWeekday: true })}`}
        </div>
        <div className="text-sm">
          <div className="flex">
            <ListItemStatus
              dataStatus={entry?.Status}
              networkStatus={entry?._SendStatus}
              date={date}
              dateToday={dateToday}
            />
          </div>
        </div>
        {entry && entry._LastModified && (
          <div className="text-xs text-gray-400">
            Updated:{" "}
            {dateToString(entry._LastModified.localised, {
              includeTime: true,
            })}
          </div>
        )}
      </div>
    </ListItem>
  ) : null;
}
