import { useState, createContext, useContext, useEffect } from "react";
import { useUser } from "hooks/useUser";

export interface IPersistantFormValuesContextType {
  setPersistantFormValue: (key: string, value: any) => void;
  getPersistantFormValue: (key: string) => any;
  clearPersistantFormValues: (key: string) => void;
}

export interface PersistantFormValuesProviderProps {
  children: React.ReactNode;
}

const PersistantFormValuesContext =
  createContext<IPersistantFormValuesContextType>({
    setPersistantFormValue: () => {},
    getPersistantFormValue: () => {},
    clearPersistantFormValues: () => {},
  });

export function PersistantFormValuesProvider({
  children,
}: PersistantFormValuesProviderProps) {
  const { isSignedIn } = useUser();

  const [persistantFormValues, setPersistantFormValues] = useState<
    Record<string, any>
  >({});

  const setPersistantFormValue = (key: string, value: any) => {
    setPersistantFormValues((prev) => ({ ...prev, [key]: value }));
  };

  const getPersistantFormValue = (key: string) => {
    return persistantFormValues[key];
  };

  const clearPersistantFormValues = (key: string) => {
    setPersistantFormValues((prev) => ({ ...prev, [key]: undefined }));
  };

  useEffect(() => {
    if (!isSignedIn) {
      setPersistantFormValues({});
    }
  }, [isSignedIn]);

  return (
    <PersistantFormValuesContext.Provider
      value={{
        setPersistantFormValue,
        getPersistantFormValue,
        clearPersistantFormValues,
      }}
    >
      {children}
    </PersistantFormValuesContext.Provider>
  );
}

export function usePersistantFormValues() {
  const context = useContext(PersistantFormValuesContext);

  if (context === undefined) {
    throw new Error(
      "usePersistantFormValues must be used within an PersistantFormValuesProvider"
    );
  }

  return context;
}
