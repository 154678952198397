import Card from "components/Card";
import { Button } from "components/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

type DynamicJsonElement = {
  tag: string;
  class?: string;
  id?: string;
  children?: DynamicJsonElement[];
  text?: string;
};

function renderJsonAsHtml(json: DynamicJsonElement): React.ReactNode {
  // Create element with or without children
  return React.createElement(
    json.tag,
    { className: json.class, id: json.id },
    // If there is text, it becomes the content of the tag
    // If there are children, recursively render them
    json.text ||
      (json.children &&
        json.children.map((childJson) => renderJsonAsHtml(childJson)))
  );
}

const DynamicFormTerms: React.FC<{
  termsUrl: string | undefined;
  handleAcceptTerms: () => void;
}> = ({ termsUrl, handleAcceptTerms }) => {
  const navigate = useNavigate();

  const [termsContent, setTermsContent] = useState<DynamicJsonElement>();

  useEffect(() => {
    if (termsUrl === undefined) return;

    fetch(termsUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((json) => {
        setTermsContent(json);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [termsUrl]);

  return (
    <Card>
      <h2 className="text-lg font-medium text-gray-600 uppercase mb-2">
        Terms &amp; Conditions
      </h2>

      <div className="mb-4">
        <div className="text-sm text-gray-500">
          Please read the following terms and conditions carefully before
          continuing.
        </div>
      </div>

      {termsContent !== undefined ? (
        <div className="text-sm text-gray-500 mb-4">
          {termsContent ? renderJsonAsHtml(termsContent) : null}
        </div>
      ) : null}

      <div className="mb-4">
        <div className="text-sm text-gray-500">
          By continuing you agree to the following terms and conditions:
        </div>
      </div>

      <div className="flex space-x-2">
        <Button onClick={() => navigate(-1)}>Cancel</Button>
        <div className="grow"></div>
        <Button theme="primary" onClick={() => handleAcceptTerms()}>
          Accept &amp; Continue
        </Button>
      </div>
    </Card>
  );
};

export default DynamicFormTerms;
