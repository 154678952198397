import { IFileUploadValue, IFormData, INetworkFormData, hasPendingFileSubmission } from "helpers/formUtilities";

export enum FORM_DATA_QUEUE_STATUS {
  UNKNOWN = 'unknown', // initial status
  PENDING = 'pending', // waiting to be sent
  SENT = 'sent', // successfully sent to server
  FAILED = 'failed', // won't retry automatically
}

export interface DBFormDataQueue {
  id: string; // uuid
  recordId: string; // DBRecord.id
  farmCode: string;
  formType: string;
  formName: string;
  moduleId: string;
  houseNumber: string;
  dateApplies: number;
  lastModified: number;
  sendAttempts: number;
  sendStatus: FORM_DATA_QUEUE_STATUS;
  data: Record<string, string>;
}

export async function serialiseFormData(formData: FormData) {
  const formDataEntries: Record<string, string> = {};

  for (const [key, value] of formData.entries()) {
    if (value instanceof File) {
      const base64String = await convertBlobToBase64(value);
      // Append the file name to the base64 string, separated by a custom delimiter, e.g., "::name::"
      formDataEntries[key] = `${base64String}::name::${value.name}`;
    } else {
      formDataEntries[key] = value;
    }
  }

  return formDataEntries;
}


function convertBlobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}


export async function deserialiseFormData(serializedData: Record<string, string>) {
  const formData = new FormData();

  for (const [key, value] of Object.entries(serializedData)) {
    if (typeof value === 'string' && value.startsWith('data:')) {
      // Check if the value contains the delimiter indicating a file name is present
      if (value.includes('::name::')) {
        const [base64Data, fileName] = value.split('::name::');
        const blob = await convertBase64ToBlob(base64Data);
        // Recreate the File object using the blob and the original file name
        const file = new File([blob], fileName, { type: blob.type });
        formData.append(key, file);
      } else {
        const blob = await convertBase64ToBlob(value);
        formData.append(key, blob);
      }
    } else {
      formData.append(key, value);
    }
  }

  return formData;
}


function convertBase64ToBlob(base64Data: string) {
  const byteString = atob(base64Data.split(',')[1]);
  const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}

export const mergeLocalDataWithNetworkData = async (
  localData: DBFormDataQueue[],
  localEntityIndices: any[],
  networkEntity: { PenValues: any[] },
  matchingLocalEntity: DBFormDataQueue
) => {
  // Remove from localData to prevent appending it twice
  localData.splice(localEntityIndices[0], 1);

  // Remove index from indices to ensure it isn't deleted
  localEntityIndices.splice(0, 1);

  const localFormData = await convertDBFormDataQueueToNetworkFormData(matchingLocalEntity);

  // Replace network entity PenValues.Values with local
  return {
    ...networkEntity,
    LastModified: localFormData.Data.LastModified,
    _SendStatus: matchingLocalEntity.sendStatus, // Add send status for display purposes only
    PenValues: networkEntity.PenValues.map((networkPen) => {
      const matchingLocalPen = localFormData.Data.PenValues.find(
        (pv) => pv.Pen.toString() === networkPen.Pen.toString()
      );

      return {
        ...networkPen,
        Values: matchingLocalPen
          ? matchingLocalPen.Values.map((value: any) => value)
          : [],
      };
    }),
  } as unknown as IFormData;
};

export const convertDBFormDataQueueToNetworkFormData = async (
  queuedData: DBFormDataQueue
) => {
  const formData = await deserialiseFormData(queuedData.data);
  const dataBlob = formData.get("data") as Blob;
  const dataText = await dataBlob.text();
  const parsedData = JSON.parse(dataText) as INetworkFormData;

  const files = formData.getAll("files") as File[];
  if (files.length) {
    // Replace file paths in the parsed data with the actual file objects.
    parsedData?.Data?.PenValues?.forEach((pen) => {
      pen?.Values?.forEach((value) => {
        if (
          hasPendingFileSubmission(value.Value) &&
          Array.isArray(value.Value.pending)
        ) {
          value.Value.pending.forEach((fileName: string, index) => {
            const file = files.find((f) => f.name.includes(fileName));
            if (file) {
              // Replace the file name with the corresponding file object.
              ((value.Value as IFileUploadValue).pending as any[])[index] =
                file;
              // console.log(`Replaced ${fileName} with file object.`);
            }
          });
        }
      });
    });
  }

  return parsedData;
};
