import {
  CheckCircleIcon,
  PlusCircleIcon,
  ExclamationCircleIcon,
  ClockIcon,
} from "assets/icons";
import { FORM_DATA_QUEUE_STATUS } from "db/FormDataQueue";

const ListIcon: React.FC<{ status: FORM_DATA_QUEUE_STATUS }> = ({ status }) => {
  function getIcon(status: FORM_DATA_QUEUE_STATUS) {
    switch (status) {
      case FORM_DATA_QUEUE_STATUS.SENT:
        return <CheckCircleIcon className="w-10 h-10 mr-2 text-success-400" />;
      case FORM_DATA_QUEUE_STATUS.PENDING:
        return <ClockIcon className="w-10 h-10 mr-2 text-warning-400" />;
      case FORM_DATA_QUEUE_STATUS.FAILED:
        return <ExclamationCircleIcon className="w-10 h-10 mr-2 text-danger-400" />;
      default:
        return <PlusCircleIcon className="w-10 h-10 mr-2 text-gray-400" />;
    }
  }

  const Icon = getIcon(status); // Add the correct type for Icon

  return Icon;
};

export default ListIcon;
