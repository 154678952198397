import lodashCloneDeep from "lodash.clonedeep";

export function uuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function checkIsUUID(value: string): boolean {
  const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return regex.test(value);
}

export function cuid() {
  return "cxxxxxxxxxxxxxxxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0xb) | 0x8;
    return v.toString(16);
  });
}

export function isNull(value: any): value is null | undefined {
  return value === null || value === undefined;
}

export function isNullEmptyOrWhitespace(
  value: any
): value is null | undefined {
  if (isNull(value)) return true;

  // check if is an empty date
  if (value instanceof Date) {
    if (value.toString() === "Invalid Date") return true;
  } else if (value instanceof Object) {
    // Object
    return Object.keys(value).length === 0;
  } else if (Array.isArray(value)) {
    // Array
    return value.length === 0;
  }

  value = value.toString().trim();
  if (value.length === 0) return true;

  return false;
}

export function isNumeric(value: any) {
  if (typeof value == "number") return true;
  if (typeof value !== "string") return false;

  return !isNaN(Number(value)) && !isNaN(parseFloat(value));
}

export function deepClone<T>(items: T): T {
  return lodashCloneDeep(items);
}
