import classNames from "classnames";

type DotIconProps = {
  className?: string;
};

const DotIcon = ({ className }: DotIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className)}
      viewBox="0 0 100 100"
    >
      <circle
        cx="50"
        cy="50"
        r="40"
        stroke="currentColor"
        stroke-width="4"
        fill="currentColor"
      />
    </svg>
  );
};

export default DotIcon;
