import Dexie, { Table } from "dexie";
import { DBFormData } from "./FormData";
import { DBLog } from "./Log";
import { DBRequest } from "./Request";
import { DBStandard } from "./Standard";
import { DBFormDataQueue } from "./FormDataQueue";

class AppDataDB extends Dexie {
  /**
   * Deprecated. Use 'formdataqueue' instead.
   */
  formdata!: Table<DBFormData>;
  formdataqueue!: Table<DBFormDataQueue>;
  standards!: Table<DBStandard>;
  logs!: Table<DBLog>;

  constructor() {
    super("app-data");

    this.version(0.1).stores({
      standards: "[farmGroup+birdType+birdSex],expires",
      formdata:
        "++ID,FarmCode,FormName,HouseNumber,DateApplies,LastModified",
    });

    this.version(0.2).stores({
      standards: "[farmGroup+birdType+birdSex],expires",
      formdata:
        "++ID,FarmCode,FormType,FormName,HouseNumber,DateApplies,LastModified",
    });

    this.version(0.3).stores({
      standards: "[farmGroup+birdType+birdSex],expires",
      formdata:
        "++ID,FarmCode,MenuOption,FormName,HouseNumber,DateApplies,LastModified",
    });

    this.version(0.5).stores({
      standards: "[farmGroup+birdType+birdSex],expires",
      formdata:
        "++ID,FarmCode,FormType,FormName,HouseNumber,DateApplies,LastModified",
      logs: "++id,timestamp, level",
    });

    this.version(0.6).stores({
      standards: "[farmGroup+birdType+birdSex],expires",
      formdata:
        "++ID,FarmCode,FormType,FormName,HouseNumber,DateApplies,LastModified",
      logs: "++id,timestamp, level",
      formdataqueue: "++id,farmCode,formType,formName,houseNumber,dateApplies,lastModified,sendAttempts,sendStatus",
    });

    this.open().then(() => {
      // Open database then...
      this.deleteOldLogRecords();
    });
  }

  private async deleteOldLogRecords() {
    const now = new Date();
    const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    // const oneMonthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
    await this.logs.where("timestamp").below(oneWeekAgo).delete();
  }
}

class WorkboxBackgroundSyncDB extends Dexie {
  requests!: Table<DBRequest>;

  constructor() {
    super("workbox-background-sync");

    this.open().catch((err) => {
      //console.warn(err.stack || err);
    });
  }
}

export const db = new AppDataDB();
export const workboxDB = new WorkboxBackgroundSyncDB();
