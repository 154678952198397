import classNames from "classnames";
import Card from "components/Card";
import { Alert, Button, ButtonClicked, Fieldset } from "components/core";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import { DATA_STATUS } from "constants.js";
import { buildFormValues } from "helpers/formsUtilities";
import {
  getFormDataStatus,
  getPenDataFromFormData,
  hasPendingFileSubmission,
  hasSavedFiles,
  IFileUploadValue,
  IFormField,
  IFormValue,
  IFormValueDataSources,
  IPenData,
  IPenFormValid,
  reduceFormDataToObject,
} from "helpers/formUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { useActiveMenu } from "hooks/useActiveMenu";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormField from "./FormField";
import { v4 as uuid } from "uuid";
import { deepClone } from "helpers/dataUtilities";
import { useMediaCreate, useMediaDelete, useMediaGetOneById } from "hooks/useMedia";
import { useFormGetMany } from "hooks/useForm";
import { toast } from "react-toastify";

interface MediaUploadFormProps {
  className?: string;
  setPageTitle: (pageTitle: string | React.ReactNode) => void;
}

export interface IMediaFormFormValues {
  PenValues: [
    {
      Pen: IPenData["Pen"];
      Values: IPenData["Values"];
    }
  ];
}

export default function MediaUploadForm({
  className,
  setPageTitle,
}: MediaUploadFormProps) {
  const { formId, id } = useParams();
  const { data: forms } = useFormGetMany();
  const navigate = useNavigate();

  const { activeMenu } = useActiveMenu();
  const moduleFeatureGroup = activeMenu?.ModuleFeatureGroup;
  const module = activeMenu?.Module;

  const { mutate: mutateMediaDelete } = useMediaDelete({
    onSuccess: (response, data) => {
      toast.success("Media deleted successfully");
      navigate(-1);
    },
    onError: (errMessage) => {
      toast.error(errMessage ?? "Failed to delete media. Please try again.");
    },
  });

  const { mutate: mutateMediaCreate } = useMediaCreate({
    onSuccess: (response, data) => {
      toast.success("Media uploaded successfully");
      navigate(-1);
    },
    onError: (errMessage) => {
      toast.error(errMessage ?? "Failed to upload media. Please try again.");
    },
  });

  const { data: media } = useMediaGetOneById({
    enabled: !isNullEmptyOrWhitespace(id),
    id: id ?? "",
  });

  const [isProcessingButtonVisible, setIsProcessingButtonVisible] =
    useState<Boolean>(false);
  const [formValid, setFormValid] = useState<IPenFormValid[]>([]);
  const [unsavedFormData, setUnsavedFormData] = useState<
    IMediaFormFormValues | undefined
  >(undefined);

  const form = useMemo(() => {
    if (!forms?.length) {
      // Return undefined to show loading state
      return undefined;
    }

    const filteredForms = forms.find(
      (f) =>
        f.FormName?.toLowerCase() === formId?.toLowerCase() &&
        f.FormType?.toLowerCase() === moduleFeatureGroup?.toLowerCase() &&
        f.ModuleName?.toLowerCase() === module?.toLowerCase()
    );

    // Return null to show error state
    return filteredForms ?? null;
  }, [forms, module, moduleFeatureGroup, formId]);

  const dataStatus = useMemo<Number | undefined>(
    () => getFormDataStatus(formValid),
    [formValid]
  );

  /** 
   * Set form data
   */
  useEffect(() => {
    if (!isNullEmptyOrWhitespace(media)) {
      console.log("Form data changed");

      const newFormValues: IMediaFormFormValues = {
        PenValues: [
          {
            Pen: "1",
            Values: [],
          },
        ],
      };

      const penValues: IFormValue[] = !!media ? Object.entries(media).map(
        ([key, value]) => {
          return {
            Ref: key,
            Value: value as any,
          };
        }
      ) : [];
      newFormValues.PenValues[0].Values = penValues;

      setUnsavedFormData(newFormValues);
    }
  }, [media]);

  /**
   * Set page title
   */
  useEffect(() => {
    const pageTitle =
      form?.FormTitle !== undefined ? form?.FormTitle : undefined;
    setPageTitle(`File Upload - ${pageTitle}`);
  }, [form?.FormTitle, setPageTitle]);

  const handleOnChangeValue = (
    field: IFormField,
    penId: string,
    value: IFormValue["Value"]
  ) => {
    if (!field?.Ref || !penId)
      return new Error("'ref' and 'pen' are required properties.");

    setUnsavedFormData((prevState) => {
      const dataSources: IFormValueDataSources = {
        this: prevState ?? {},
        // _custom: {
        //   farmId: farmId,
        //   houseId: houseId,
        // },
        _field: field,
      };

      try {
        const newState = buildFormValues(
          field,
          penId,
          value,
          form,
          dataSources,
          undefined,
          undefined,
          0,
          undefined
        );

        return newState;
      } catch (error) {
        console.error(error);
      }

      return prevState as any;
    });
  };

  const handleOnValidate = (
    fieldId: string, // We use the id here because the field.Ref is not unique. (e.g. when using repeater fields `field.Ref_1`)
    field: IFormField,
    penNumber: string,
    valid: boolean,
    complete: boolean,
    required: boolean
  ) => {
    setFormValid((prevState) => {
      const newFormValid = prevState.filter(
        (fv) =>
          !(
            fv.Ref === fieldId &&
            (isNullEmptyOrWhitespace(field.QuestionGroup) ||
              fv.QuestionGroup === field.QuestionGroup) &&
            fv.Pen.toString() === penNumber.toString()
          )
      );
      newFormValid.push({
        Ref: fieldId,
        QuestionGroup: field.QuestionGroup,
        Pen: penNumber,
        Valid: valid,
        Complete: complete,
        Required: required,
      });

      return newFormValid;
    });
  };

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    if (dataStatus === DATA_STATUS.ERROR) {
      toast.error("The form contains invalid data. Please fix and try again.");

      return;
    } else if (unsavedFormData === undefined || dataStatus !== DATA_STATUS.COMPLETE) {
      toast.error("Form is not complete. Please fill out all required fields");

      return;
    }

    setIsProcessingButtonVisible(true);

    const clonedFormValues = deepClone(unsavedFormData);

    const formValueObj = reduceFormDataToObject(clonedFormValues);

    // Build FormData as part of multipart form post
    const formData = new FormData();
    const formName = form?.FormName ?? "unknown";
    const recordId = id;

    for (const [index, value] of Object.entries(formValueObj)) {
      if (hasPendingFileSubmission(value)) {
        const fileUploadValue = value as IFileUploadValue;
        const pendingFiles = fileUploadValue.pending;
        const fileUrls: string[] = [];

        for (const file of pendingFiles as File[]) {
          const fileExt = file.name.split(".").pop();
          const fileID = uuid();
          const newFileName = `${fileID}.${fileExt}`
          const newFilePath = `${formName}/${newFileName}`;
          formData.append("files", file, newFilePath);
          fileUrls.push(newFilePath);
        }

        // Replace pending files with fileIDs
        // These will then be replaced on the server with the correct file url
        formValueObj[index] = {
          ...fileUploadValue,
          pending: fileUrls,
        };
      }
    }

    if (recordId !== undefined) {
      formValueObj.id = recordId;
    }
    formValueObj.formName = formName;

    // Append data
    const blob = new Blob([JSON.stringify(formValueObj)], {
      type: "application/json",
    });
    formData.append("data", blob);

    await mutateMediaCreate(formData);

    setIsProcessingButtonVisible(false);
  };

  const handleOnDelete = async () => {
    if (id === undefined) {
      return;
    }

    if (
      window.confirm(
        "Are you sure you wish to delete this record? This action cannot be undone."
      )
    ) {

      const clonedFormValues = deepClone(unsavedFormData);

      const formValueObj = reduceFormDataToObject(clonedFormValues);

      const filesToDelete: string[] = [];
      for (const value of Object.values(formValueObj)) {
        if (hasSavedFiles(value)) {
          const fileUploadValue = value as IFileUploadValue;
          const savedFilesArray = fileUploadValue.saved;
          filesToDelete.push(...savedFilesArray);
        }
      }
        
      await mutateMediaDelete(filesToDelete);


        // const errors = responseBody.d.failed.reduce((acc: string[], curr) => {
        //   if (curr.errorMessage) {
	      //     acc.push(curr.errorMessage);
        //   } else {
        //     acc.push(`File ${curr.url} failed to delete.`);
        //   }
	      //   return acc;
	      // }, []);

        // if (errors.length > 0) {
        //   addNotification({
        //     title: "Failed",
        //     theme: "error",
        //     description: errors.join("\r\n\r\n"),
        //   });          
        // } else {
        //   addNotification({
        //     title: "Success",
        //     theme: "success",
        //     description:
        //       "Record has been deleted.",
        //   });
  
        //   return navigate(buildListPageUrl(activeMenu));
        // }
    }
  };

  const handleClickCancel = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    navigate(-1);
  };

  const isLoading = 
    form === undefined || 
    (
      !isNullEmptyOrWhitespace(id) && 
      isNullEmptyOrWhitespace(unsavedFormData?.PenValues?.[0]?.Values)
    );

  if (isLoading) {
    // form is loading
    return <FieldsetSkeleton />;
  }

  if (form === null) {
    // form does not exist
    return <Alert theme="danger">Media upload form not found.</Alert>;
  }

  return (
    <Card>
      <Fieldset
        title={form?.FormTitle}
        text={undefined}
        content={form?.FormSubTitle}
      >
        <div className={classNames("grid grid-cols-4 gap-4", className)}>
          {form.FormFields.map((ff) => (
            <FormField
              key={ff.Ref}
              {...{
                id: ff.Ref,
                field: ff,
                penNumber: "1",
                setFieldValue: handleOnChangeValue,
                setFieldValid: handleOnValidate,
                formValues: getPenDataFromFormData("1", unsavedFormData as any),
              }}
            />
          ))}

          <div className="col-span-full">
            <div className="flex">
              <div className="flex-grow">
                <Button
                  className="justify-self-start"
                  type="button"
                  onClick={handleClickCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="justify-self-start"
                  type="button"
                  theme="danger"
                  onClick={handleOnDelete}
                >
                  Delete
                </Button>
              </div>
              <div>
                {isProcessingButtonVisible ? (
                  <ButtonClicked />
                ) : (
                  <Button theme="primary" onClick={handleSubmit}>
                    Save
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fieldset>
    </Card>
  );
}
