import { useState, useRef, forwardRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "assets/Logo";
import { InitialsAvatar } from "components/core/Avatars/InitialsAvatar";
import { generateInitialsFromText } from "helpers/stringUtilities";
import useClickOutside from "hooks/useClickOutside";
import { useUser, useUserSignOut } from "hooks/useUser";
import { MenuIcon, XIcon } from "assets/icons";
import { useSidebar } from "context/SidebarProvider";
import useNetworkStatus from "hooks/useNetworkStatus";
import { toast } from "react-toastify";

export default function Navbar(props) {
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const { sidebarOpen, setSidebarOpen } = useSidebar();
  const mainMenuRef = useRef(null);
  const mainMenuButtonRef = useRef(null);
  const profileMenuRef = useRef(null);
  const profileMenuButtonRef = useRef(null);
  
  useClickOutside(mainMenuRef, handleClickOutsideMainMenu);
  useClickOutside(profileMenuRef, handleClickOutsideProfileMenu);

  const { isOnline } = useNetworkStatus();
  
  const { user } = useUser();

  let location = useLocation();

  const handleToggleProfileMenu = () => {
    setOpenProfileMenu((current) => !current);
  };

  function handleClickOutsideMainMenu(ev) {
    if (
      !sidebarOpen ||
      mainMenuButtonRef.current.contains(ev.target) // Don't update state if menu button is clicked to prevent double triggering.
    )
      return;
      setSidebarOpen(false);
  }

  function handleClickOutsideProfileMenu(ev) {
    if (
      !openProfileMenu ||
      profileMenuButtonRef.current.contains(ev.target) // Don't update state if menu button is clicked to prevent double triggering.
    )
      return;
    setOpenProfileMenu(false);
  }


  if (location.pathname.toLowerCase() === "/user/login") {
    // Don't show on login page
    return null;
  }

  return (
    <nav
      data-cy="navbar"
      className="relative z-30 bg-primary-darkest2 dark:bg-gray-800 border-b border-primary-darker dark:border-gray-900 text-white dark:text-gray-300"
    >
      <div className="px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="sticky top-0 z-10 flex -ml-2 h-16 flex-shrink-0">
            {/* Mobile menu button */}
            <MenuButton sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          </div>
          <div className="flex-1 flex items-center justify-start sm:items-stretch sm:justify-start ml-4">
            <div className="shrink-0 flex items-center">
              <Link to="/">
                <Logo style={{ height: "44px" }} />
              </Link>
            </div>
          </div>
          <div
            className={`${
              !user ? "hidden" : ""
            } absolute z-50 inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0`}
          >
            {/* Profile dropdown */}
            <div className="ml-3 relative">
              <div>
                <button
                  ref={profileMenuButtonRef}
                  className={`flex text-sm rounded-full outline-none ring-2 ring-offset-1 ring-offset-primary-darkest2 dark:ring-offset-gray-800 relative ${
                    isOnline ? "ring-success-500" : "ring-gray-500"
                  }`}
                  id="user-menu"
                  aria-haspopup="true"
                  onClick={handleToggleProfileMenu}
                >
                  <span className="sr-only">Open user menu</span>
                  {/* <img
                    className="h-8 w-8 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixqx=lPPMWzHpli&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  /> */}
                  {user && (
                    <InitialsAvatar
                      initials={generateInitialsFromText(`${user.fullname}`)}
                    />
                  )}
                  <span className="flex h-3 w-3 absolute -right-1 bottom-0">
                    {isOnline && (
                      <span
                        className={`animate-ping absolute inline-flex h-full w-full rounded-full bg-success-500`}
                      ></span>
                    )}
                    <span
                      className={`relative inline-flex rounded-full h-3 w-3 ${
                        isOnline ? "bg-success-500" : "bg-gray-500"
                      } ring-1 ring-primary-darkest2 dark:ring-gray-800`}
                    ></span>
                  </span>
                </button>
              </div>
              <ProfileMenu isOpen={openProfileMenu} isOnline={isOnline} ref={profileMenuRef} />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

const NetworkStatus = ({ isOnline }) => {
  return (
    <div
      className="block px-4 py-2 text-sm text-gray-700 bg-gray-100 hover:bg-white"
      role="menuitem"
    >
      Status: <NetworkStatusBadge isOnline={isOnline} />
    </div>
  );
};

const NetworkStatusBadge = ({ isOnline }) => {
  if (!isOnline) {
    return (
      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-800">
        Offline
      </span>
    );
  }

  return (
    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-success-200 text-success-800">
      Online
    </span>
  );
};

const RefreshPageLink = ({ isOnline }) => {
  if (!isOnline) {
    return null;
  }

  const handleClickRefreshPage = () => {
    window.location.reload();
  };

  return (
    <Link
      to="/"
      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
      role="menuitem"
      onClick={handleClickRefreshPage}
    >
      Refresh page
    </Link>
  )
};

const SignOutLink = ({ isOnline }) => {
  const navigate = useNavigate();

  const { mutate: mutateUserSignOut } = useUserSignOut({
    onSuccess: () => {
      toast.success("Signed out successfully.");

      navigate("/user/login");
    },
    onError: (errMessage) => {
      toast.error(errMessage ?? "Error signing out user");
    },
  });

  if (!isOnline) {
    return null;
  }

  const handleClickSignOut = (ev) => {
    ev.preventDefault();

    mutateUserSignOut();
  };

  return (
    <Link
      to="/"
      className="text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      role="menuitem"
      onClick={handleClickSignOut}
    >
      Sign out
    </Link>
  )
};

const MenuButton = forwardRef(({ sidebarOpen, setSidebarOpen }, ref) => {
  const handleToggleMainMenu = () => {
    setSidebarOpen((current) => !current);
  };

  return (
    <button
      ref={ref}
      type="button"
      className="px-4 text-white dark:text-gray-400 hover:text-primary dark:hover:text-primary focus:outline-none focus:ring-0"
      // className={classNames("inline-flex items-center justify-center p-2 text-white focus:outline-none focus:shadow-outline",
      // props.sidebarOpen ? "opacity-100" : "opacity-50")}
      aria-controls="mobile-menu"
      aria-expanded="false"
      onClick={handleToggleMainMenu}
    >
      <span className="sr-only">Open main menu</span>
      <MenuIcon className={`h-6 w-6 ${sidebarOpen ? "hidden" : "block"}`} />
      <XIcon className={`h-6 w-6 ${sidebarOpen ? "block" : "hidden"}`} />
    </button>
  );
});

const ProfileMenu = forwardRef(({ isOpen, isOnline }, ref) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div
      ref={ref}
      className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow py-1 bg-white ring-1 ring-black ring-opacity-5 opacity-100 scale-100 z-10 divide-y divide-gray-100"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="user-menu"
    >
      <NetworkStatus isOnline={isOnline} />
      {/* <Link
      to="/user/profile"
      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      role="menuitem"
      onClick={handleToggleProfileMenu}
    >
      Your Profile
    </Link> */}
      {/* <Link
      to="/user/settings"
      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      role="menuitem"
      onClick={handleToggleProfileMenu}
    >
      Settings
    </Link> */}
      <Link
        to="/debug"
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        role="menuitem"
      >
        About App
      </Link>
      <Link
        to="/sync-manager"
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        role="menuitem"
      >
        Sync manager
      </Link>
      <RefreshPageLink isOnline={isOnline} />
      <SignOutLink isOnline={isOnline} />
    </div>
  );
});
