import { useNavigate } from "react-router-dom";
import { dateToString } from "helpers/dateUtilities";
import { ListItem } from "components/core";
import ListItemIcon from "components/Schedule/ListItemIcon";
import ListItemStatus from "components/Schedule/ListItemStatus";
import { buildFormPageUrl } from "helpers/redirectUtilities";
import { useActiveMenu } from "hooks/useActiveMenu";

export default function ListItemWeekly({
  entry,
  date: scheduleItemDate,
  dateString,
  dateToday,
  toDate,
  fromDate,
  formId,
  className,
  ...other
}) {
  let navigate = useNavigate();
  const { activeMenu } = useActiveMenu();

  //#region Callbacks

  /**
   * Handle list item click
   */
  const handleClick = () => {
    const recordId =
      entry?._DateApplies?.normalised?.getTime() ?? scheduleItemDate.getTime();

    return navigate(
      buildFormPageUrl(
        activeMenu,
        formId,
        null,
        null,
        recordId
      )
    );
  };

  //#endregion

  return (
    <ListItem aria-label="weekly" onClick={() => handleClick()} {...other}>
      <ListItemIcon
        dataStatus={entry?.Status}
        networkStatus={entry?._SendStatus}
        date={toDate}
        dateToday={dateToday}
      />
      <div className="flex-grow">
        <div className="font-medium">Weekly</div>
        <div className="text-sm text-gray-900" data-cy="date-range">
          {fromDate && `${dateToString(fromDate, { includeWeekday: true })} -`}{" "}
          {toDate && `${dateToString(toDate, { includeWeekday: true })}`}
        </div>
        <div className="text-sm">
          <div className="flex">
            <ListItemStatus
              dataStatus={entry?.Status}
              networkStatus={entry?._SendStatus}
              date={scheduleItemDate}
              dateToday={dateToday}
            />
          </div>
        </div>
        {entry && entry._LastModified && (
          <div className="text-xs text-gray-400">
            Updated:{" "}
            {dateToString(entry._LastModified.localised, {
              includeTime: true,
            })}
          </div>
        )}
      </div>
    </ListItem>
  );
}
