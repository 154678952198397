import { MinusCircleIcon, PlusCircleIcon, SwitchVerticalIcon } from "assets/icons";
import Card, { CardTitle } from "components/Card";
import { Alert, Button, Input, MultiSelect, Select } from "components/core";
import { stripChartIdIncrement } from "helpers/dashboardUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { lazy, Suspense, useState } from "react";
import { IChartKey, IChartKeyEvent, IChartKeyOperand, IMetric } from "../types";
import { OperandsForm } from "./OperandsForm";
import { EventsForm } from "./EventsForm";
import { SelectedChartKeyList } from "./SelectedChartKeyList";
import { toast } from "react-toastify";

const ReactSortable = lazy(() =>
  import("react-sortablejs").then((module) => ({
    default: module.ReactSortable,
  }))
);

interface IEditChartKeyFormProps {
  selectedChartKeys: IChartKey[];
  metrics: IMetric[];
  isComparisonAllowed: boolean;
  isEventsAllowed: boolean;
  onClickSave: (selectedChartKeys: IChartKey[]) => void;
}

export function EditChartKeyForm(props: IEditChartKeyFormProps) {
  const [selectedChartKeys, setSelectedChartKeys] = useState(props.selectedChartKeys ?? []);
  const [selectedMetricId, setSelectedMetricId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedDataType, setSelectedDataType] = useState("");
  const [selectedOperands, setSelectedOperands] = useState<
    IChartKeyOperand[]
  >([]);
  const [selectedEvents, setSelectedEvents] = useState<IChartKeyEvent[]>([]);
  const [editingChartKeyIndex, setEditingChartKeyIndex] = useState<number>(-1);
  const [error, setError] = useState("");

  const selectedMetric = props.metrics.find((item) => item.id === selectedMetricId);

  const editingChartKey = selectedChartKeys.length && editingChartKeyIndex !== -1 ? selectedChartKeys[editingChartKeyIndex] : undefined;

  function onChangeMetric(value: string) {
    setSelectedMetricId(value);
  }

  function onChangeMetricTitle(value: string) {
    setSelectedTitle(value);
  }

  function onChangeSelectedOperandValue(
    index: number,
    key: string,
    value: string
  ) {
    setSelectedOperands((prevValues) => {
      const newValues = prevValues.map((item) => item);
      newValues[index] = {
        ...newValues[index],
        [key]: value,
      };

      return newValues;
    });
  }

  function onChangeSelectedEvent(index: number, key: string, value: string) {
    setSelectedEvents((prevValues) => {
      const newValues = prevValues.map((item) => item);
      newValues[index] = {
        ...newValues[index],
        [key]: value,
      };

      return newValues;
    });
  }

  function onClickAddOperand() {
    setSelectedOperands((prevValues) => {
      const newValues = prevValues.map((item) => item);
      newValues.push({
        operator: ">",
        values: [],
        color: "#16a34a",
        tolerance: 0,
        toleranceUnit: "number",
      });
      return newValues;
    });
  }

  function onClickAddEvent() {
    setSelectedEvents((prevValues) => {
      const newValues = prevValues.map((item) => item);
      newValues.push({
        type: "onclick",
        title: "",
        func: "",
        args: [],
      });
      return newValues;
    });
  }

  function removeSelectedChartKey(index: number) {
    setSelectedChartKeys((prev) =>
      prev.filter((item, i) => i !== index)
    );
  }

  function sortSelectedListOptions(reorderedList: IChartKey[]) {
    setSelectedChartKeys(reorderedList);
  }

  function onSortComparisonList(reorderedList: IChartKeyOperand[]) {
    // console.log("reorderedList", reorderedList);
    setSelectedOperands(reorderedList);
  }

  function resetSelectedFormValues() {
    setSelectedMetricId("");
    setSelectedTitle("");
    setSelectedDataType("");
    setSelectedOperands([]);
  }

  function onClickAddChartKeys(editingChartKeyIndex: number) {
    const errors = validateForm();
    setError(errors);
    if (errors) {
      return;
    }

    if (!selectedMetric) {
      toast.error("Metric not found");
  
      return;
    }

    const newChartKey: IChartKey = {
      id: selectedMetric.id,
      title: !isNullEmptyOrWhitespace(selectedTitle)
        ? selectedTitle
        : selectedMetric.title,
      type: selectedDataType,
    };

    if (selectedOperands.length > 0) {
      newChartKey.type = "expression";

      newChartKey.operands = selectedOperands.map((operand) => {
        // prettier-ignore
        const isConditionalOperator = [">=", "<=", ">", "<", "between", "=="].includes(operand.operator);

        const values = operand.values.map((value) => {
          if (value.type === "metric") {
            const metric = props.metrics.find(
              (metric) => metric.id === value.value
            );
            if (!metric) {
              return {
                type: "value",
                value: value.value,
                title: value.value,
              };
            }

            return {
              type: "metric",
              value: metric.id,
              title: metric.title,
            };
          }

          return {
            type: "value",
            value: value.value,
            title: value.value,
          };
        });

        if (isConditionalOperator) {
          return {
            color: operand.color,
            operator: operand.operator,
            tolerance: operand.tolerance || 0,
            toleranceUnit: operand.toleranceUnit,
            values: values,
          }
        }

        return {
          operator: operand.operator,
          values: values,
        };
      });
    }

    if (selectedEvents.length > 0) {
      newChartKey.events = selectedEvents.map((event) => {
        const { title, type, func, args } = event;

        return {
          title,
          type,
          func,
          args: [...args],
        };
      });
    }

    if (editingChartKeyIndex !== -1) {
      // EDITING
      const newSelectedMetrics = selectedChartKeys.map((item, i) =>
        i === editingChartKeyIndex ? newChartKey : item
      );
      setSelectedChartKeys(newSelectedMetrics);
      setEditingChartKeyIndex(-1);
    } else {
      // ADDING
      setSelectedChartKeys((current) => current.concat([newChartKey]));
    }

    resetSelectedFormValues();

    const selectedChartKeyListElement = document.getElementById("selected-chartkey-list");
    if (selectedChartKeyListElement) {
      // Delay scroll to allow for form to render
      setTimeout(() => {
        selectedChartKeyListElement.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }

  function onClickEditChartKey(index: number) {
    setEditingChartKeyIndex(index);
    const chartKey = selectedChartKeys[index];

    setSelectedMetricId(stripChartIdIncrement(chartKey.id));
    setSelectedTitle(chartKey.title);
    setSelectedDataType(chartKey?.type ?? "");
    setSelectedOperands(chartKey.operands || []);
    setSelectedEvents(chartKey.events || []);

    const editChartKeyFormElement = document.getElementById("edit-chartkey-form");
    if (editChartKeyFormElement) {
      // Delay scroll to allow for form to render
      setTimeout(() => {
        editChartKeyFormElement.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }

  function onClickCancelEditingChartKey() {
    setEditingChartKeyIndex(-1);
    resetSelectedFormValues();
  }

  function validateForm() {
    if (isNullEmptyOrWhitespace(selectedMetricId)) {
      return "Please fill out all required fields to continue.";
    }

    for (const operand of selectedOperands) {
      if (
        !operand.values.length ||
        operand.values.some((value) => isNullEmptyOrWhitespace(value.value))
      ) {
        return "Please fill out all required fields to continue.";
      }
    }

    return "";
  }

  return (
    <div className="grid grid-cols-1 gap-6 py-4">
      {error && (
        <div className="col-span-full">
          <Alert theme="danger">{error}</Alert>
        </div>
      )}

      <Card className="border border-gray-200" bodyClassName="space-y-4">
        <CardTitle>Chart Key(s)</CardTitle>
        <SelectedChartKeyList
          id="selected-chartkey-list"
          selectedChartKeys={selectedChartKeys}
          onRemoveSelectedMetric={removeSelectedChartKey}
          onSortSelectedListOptions={sortSelectedListOptions}
          onClickEditChartKey={onClickEditChartKey}
        />

        <div className="flex justify-between">
          <Button
            type="button"
            size="small"
            theme="primary"
            onClick={() => props.onClickSave(selectedChartKeys)}
          >
            Apply &amp; close
          </Button>
        </div>
      </Card>

      <Card id="edit-chartkey-form" className="border border-gray-200" bodyClassName="space-y-4">
        <CardTitle>{editingChartKeyIndex !== -1 ? `Editing ${editingChartKey?.title}` : "Add Chart Key"}</CardTitle>

        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-full">
            <MultiSelect
              id="metric-select"
              key={`metric-${selectedMetricId}-select`}
              label="Metric"
              labelPosition="inset"
              setValue={(value) => onChangeMetric(value)}
              value={selectedMetricId}
              listOptions={props.metrics.map((item) => ({
                Id: item.id,
                Text: item.title,
                Value: item.id,
              }))}
              showSearch={true}
              sortable={false}
              limit={1}
              disableCalcTrigger={true}
              required={true}
              asPortal={true}
            />
          </div>
          {selectedMetricId ? (
            <div className="col-span-full">
              <Input
                id="metric-title-input"
                key={`metric-title-input`}
                label="Metric Title"
                labelPosition="inset"
                hint="Override the metric title"
                value={selectedTitle}
                setValue={(value: string) => onChangeMetricTitle(value)}
                placeholder={
                  props.metrics.find((item) => item.id === selectedMetricId)
                    ?.title
                }
                required={false}
                disableCalcTrigger={true}
              />
            </div>
          ) : null}
          <div className="col-span-full">
            <Select
              id="datatype-select"
              key={`datatype-${selectedMetricId}-select`}
              label="Data Type"
              labelPosition="inset"
              setValue={(value) => setSelectedDataType(value)}
              value={selectedDataType}
              listOptions={[
                { Id: "string", Text: "String", Value: "" },
                { Id: "number", Text: "Number", Value: "number" },
                { Id: "percent", Text: "Percent", Value: "percent" },
                // { Id: "currency", Text: "Currency", Value: "currency" },
                { Id: "date", Text: "Date", Value: "date" },
              ]}
              placeholder={""}
            />
          </div>

          <div className="col-span-full flex justify-between">
          <Button
            type="button"
            size="small"
            onClick={onClickCancelEditingChartKey}
          >
            Cancel
          </Button>
          {selectedMetricId && !error ? (
              <Button
                type="button"
                theme="primary"
                size="small"
                disabled={!!error}
                onClick={() => onClickAddChartKeys(editingChartKeyIndex)}
                icon={<PlusCircleIcon className="w-4 h-4 mr-1" />}
              >
                {editingChartKeyIndex !== -1 ? `Update Chart Key` : "Add Chart Key"}
              </Button>
          ) : null}
        </div>
        </div>

        {props.isComparisonAllowed && selectedMetricId ? (
          <div className="space-y-4 pt-8">
            <div>Comparison(s)</div>
            {selectedOperands.length === 0 ? (
              <div className="text-sm text-gray-500">
                Get started by creating a comparison operand.
              </div>
            ) : null}

            {selectedOperands?.length ? (
              <Suspense
                fallback={
                  <div className="text-sm text-gray-500">
                    Loading selected items...
                  </div>
                }
              >
                <ReactSortable
                  tag="div"
                  className="space-y-2 divide-y text-sm items-center"
                  list={selectedOperands}
                  setList={onSortComparisonList}
                  handle=".sort-handle"
                  preventOnFilter={true}
                >
                  {selectedOperands.map((item, index) => (
                    <div className="grid grid-cols-12 items-center pt-2">
                      <div className="col-span-1 sort-handle cursor-pointer group">
                        <SwitchVerticalIcon className="w-4 h-4 text-gray-300 group-hover:text-gray-500" />
                      </div>
                      <div className="col-span-10">
                        <OperandsForm
                          selectedMetricTitle={!isNullEmptyOrWhitespace(selectedTitle) ? selectedTitle : selectedMetric?.title ?? ""}
                          metrics={props.metrics}
                          selectedOperand={selectedOperands[index]}
                          onChangeSelectedOperandValue={(key, value) =>
                            onChangeSelectedOperandValue(index, key, value)
                          }
                        />
                      </div>
                      <div className="col-span-1 inline-flex justify-end">
                        <MinusCircleIcon
                          className="w-4 h-4 text-gray-500 hover:text-primary cursor-pointer"
                          onClick={() => {
                            setSelectedOperands((prevValues) => {
                              const newValues = prevValues.map((item) => item);
                              newValues.splice(index, 1);
                              return newValues;
                            });
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </ReactSortable>
              </Suspense>
            ) : null}

            <Button
              size="small"
              type="button"
              icon={<PlusCircleIcon className="w-4 h-4 mr-1" />}
              onClick={onClickAddOperand}
            >
              Add Operand
            </Button>
          </div>
        ) : null}

        {props.isEventsAllowed && selectedMetricId ? (
          <div className="space-y-4 pt-8">
            <div>Event(s)</div>

            {selectedEvents.length === 0 ? (
              <div className="text-sm text-gray-500">
                Get started by creating an event.
              </div>
            ) : null}

            {selectedEvents.map((item, index) => (
              <div className="col-span-full grid grid-cols-12 items-center">
                <div className="col-span-11">
                  <EventsForm
                    metrics={props.metrics}
                    selectedEvent={selectedEvents[index]}
                    onChangeSelectedEvent={(key, value) =>
                      onChangeSelectedEvent(index, key, value)
                    }
                  />
                </div>
                <div className="col-span-1 inline-flex justify-end">
                  <MinusCircleIcon
                    className="w-4 h-4 text-gray-500 hover:text-primary cursor-pointer"
                    onClick={() => {
                      setSelectedEvents((prevValues) => {
                        const newValues = prevValues.map((item) => item);
                        newValues.splice(index, 1);
                        return newValues;
                      });
                    }}
                  />
                </div>
              </div>
            ))}

            <Button
              size="small"
              type="button"
              icon={<PlusCircleIcon className="w-4 h-4 mr-1" />}
              onClick={onClickAddEvent}
            >
              Add Event
            </Button>
          </div>
        ) : null}
      </Card>
    </div>
  );
}
