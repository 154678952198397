import { LockClosedIcon } from "@heroicons/react/outline";
import Input from "./input";
import Button from "./button";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { useUser, useUserSignIn } from "hooks/useUser";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";

const SigninForm: React.FC = () => {
  const navigate = useNavigate();
  const { isSignedIn, user } = useUser();
  const { mutate: mutateUserSignin, isLoading: isSigningIn } = useUserSignIn({
    onSuccess: (response, responseBody) => {
      toast.success("User signed in successfully.");

      navigate("/");
    },
    onError: (errMessage) => {
      toast.error(errMessage ?? "Error signing in user");
    },
  });

  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
  });

  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const checkIsFormValid = () => {
    const isValid = Object.values(formErrors).every((x) =>
      isNullEmptyOrWhitespace(x)
    );

    return isValid;
  };

  const validateForm = () => {
    const newErrors = {
      username: "",
      password: "",
    };
    let isValid = true;

    if (isNullEmptyOrWhitespace(usernameRef.current?.value ?? "")) {
      newErrors.username = "Username is required";
      isValid = false;
    }

    if (isNullEmptyOrWhitespace(passwordRef.current?.value ?? "")) {
      newErrors.password = "Password is required";
      isValid = false;
    }

    setFormErrors(newErrors);

    return isValid;
  };

  const handleSignIn = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const username = formData.get("username")?.toString() ?? "";
    const password = formData.get("password")?.toString() ?? "";

    if (checkIsFormValid()) {
      mutateUserSignin({ username, password });
    }
  };

  const handleChangeInput = () => {
    validateForm();
  };

  useEffect(() => {
    validateForm();
  }, []);

  return (
    <>
      {isSignedIn ? (
        <>
          <div>Hi {user?.fullname ?? "Unknown"}!</div>
          <div>
            <a className="text-primary hover:text-primary-dark" href="/">
              Go to app dashboard
            </a>
          </div>
        </>
      ) : (
        <>
          <div className="flex-grow text-lg font-medium uppercase text-gray-600">
            Sign in to your account
          </div>
          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={handleSignIn} className="space-y-6">
                <Input
                  ref={usernameRef}
                  id="username"
                  name="username"
                  label="Username"
                  type="text"
                  onChange={handleChangeInput}
                />

                <Input
                  ref={passwordRef}
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  onChange={handleChangeInput}
                />

                {isSigningIn ? (
                  <div>Signing in...</div>
                ) : (
                  <Button
                    type="submit"
                    className="relative"
                    disabled={!checkIsFormValid()}
                  >
                    <LockClosedIcon className="h-5 w-5 mr-2 text-primary-light group-hover:text-primary-light" />
                    Sign in
                  </Button>
                )}
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SigninForm;
